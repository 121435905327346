import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
// import Timer from "react-timer-wrapper";
import { ProgressBar } from "react-bootstrap";
import scrollToComponent from "react-scroll-to-component";
import Dropzone from "react-dropzone";
import Immutable from "seamless-immutable";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import { toBuffer } from "ip";
import { NotificationManager } from "react-notifications";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

import DocumentFormSteps from "./DocumentFormSteps";
import DocumentFormFields from "./DocumentFormFields";
import DocumentFormPreview from "./DocumentFormPreview";
import ActiveUrlLabel from "../ActiveUrlLabel";
import ActiveLabel from "../ActiveLabel";
import Fade from "./Fade";
import Switch from "../Switch.js";
import LoadSavedData from "./LoadSavedData";
import { CustomFadeModal } from "../PopUp";

import {
  makePATHUrl,
  // makeSEFUrl,
  checkCookieConsent,
  acceptedFileFormats,
  maximumFileSizeToUpload,
  buildContent,
  getQueryParamStatus,
  getQueryParam,
  isMobile,
} from "../../helpers";
import strings from "../../services/strings";
import documents from "../../services/documents";
import auth from "../../services/auth";
import language from "../../services/language";

import "./DocumentForm.scss";

import * as settingsAction from "../../store/settings/actions";

const nl2br = require("react-nl2br");

class DocumentForm extends Component {
  rendered = {}; // current rendered fields
  progressed = {}; // current progressed fields
  totalFields  = null;
  constructor(props) {
    super(props);
    this.state = {
      currentStep: null,
      isFirstStep: false,
      isLastStep: false,
      hasErrors: {}, // keep track of invalid fields
      steps: null,
      rules: null,
      paths: null,
      price: "",
      paidDoc: "",
      customerName: "",
      documentName: "",
      shareEmails: [],
      shareEmail: "",
      shareEmailId: -1,
      userEmail: "",
      showError: false,
      showCustomerNameError: false,
      showEmailPushError: false,
      user: null,
      require: false,
      in: false,
      forceBackDrop: "hidden",
      hasWarnings: {}, // keep track of warning fields
      documentProgress: 0,
      docTimerTimeLeft: 0,
      docTimerDuration: 0,
      docTimerPerFieldTime: 15000, // 15SEC
      stepFieldsCount: [],
      applySettings: true,
      loggedUserEmail: "",
      showLogo: false,
      fontFamily: "verdana",
      fontSize: "12",
      fontLoaded: false,
      headerLogoPosition: "left",
      headerCompanyPosition: "right",
      footerPosition: "left",
      headerDisplay: null,
      footerDisplay: null,
      showPreviewUp: true,
      showPreview: true,
      fieldsColumn: "col-sm-6",
      documentType: "doc",
      fields: {},
      sendToOwnerConfirm: true,
      attachDocument: [],
      attachDocumentOnServer: [],
      highlightKeys: [],
      preFilledFields: {},
      selectorType: "",
      attachmentsFromPropsStatus: false,
      documentComments:
        this.props.drafts && this.props.drafts.draftComments
          ? this.props.drafts.draftComments
          : {},
      fieldsOrder: {},
      isWorkflow: false,
      isShared: false,
      expandGlossaries: {},
      glossariesCodeNameToId: {},
      glossariesCodeIdToName: {},
      saved: false,
    };
  }

  hidePopoverModal = () => {
    this.refs.overlay.hide();
  };

  showModal = () => {
    this.refs.modal1.show();
  };

  hideModal = () => {
    this.refs.modal1.hide();
  };

  // togglePreview() {
  //   this.refs.modal1.hide();
  // }

  componentDidMount() {
    let userEmail = localStorage.getItem("loggedInUserEmail");
    this.setState({ loggedUserEmail: userEmail });
    this.setState({ userEmail: userEmail });
    this.setState({ user: auth.getLocalUser() ? auth.getLocalUser() : null });
    this.initHeight();
    if (
      this.props.selectorType === "me" ||
      this.props.selectorType === "client"
    ) {
      this.setState({ selectorType: this.props.selectorType });
    }
    if (this.props.drafts && this.props.drafts.draftComments) {
      this.setState({
        documentComments: this.props.drafts.draftComments,
      });
    }
    this.initFontSettings();
    this.setFieldsOrder();
    this.checkIsWorkflow();
    this.checkIsShared();
    this.loadGlossaries();
  }

  componentWillUnmount() {
    sessionStorage.clear();
  }
  componentDidUpdate(prevProps) {
    if (
      _.size(prevProps.stepsFromValidation) === 0 &&
      _.size(this.props.stepsFromValidation) > 0
    ) {
      this.tryLoadCurrentItem();
    }

    if (this.props.drafts.currentItemId) {
      let currentDraftId = this.props.drafts.currentItemId.substr(1),
        theDraftLatestId = this.props.drafts.latestRevisionId;
      // theDraftOwnerId = this.props.drafts.draftOwnerId,
      // loggedUserId = this.state.user.id;
      if (
        // theDraftOwnerId &&
        theDraftLatestId &&
        currentDraftId !== theDraftLatestId
        // theDraftOwnerId !== loggedUserId
      ) {
        this.refs.needToMoveLatestRevisionModal.show();
      }
    }
    if (
      this.props.profile &&
      this.props.profile.font_family_setting &&
      this.props.profile.font_size_setting &&
      this.props.currentCategory
    ) {
      if (
        this.props.profile.font_family_setting !== this.state.fontFamily ||
        this.props.profile.font_size_setting !== this.state.fontSize
      ) {
        this.initFontSettings();
      }
    }
    this.checkIsShared();
    //check new attachment url after renaming a attachment
    if (
      _.size(this.props.attachmentsUrl) &&
      this.props.attachmentsUrl !== prevProps.attachmentsUrl
    ) {
      this.handlePropsAttachmentsUrl(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !Object.keys(this.state.documentComments).length &&
      nextProps.drafts.draftComments !== this.props.drafts.draftComments
    ) {
      this.setState({
        documentComments: nextProps.drafts.draftComments,
      });
    }
    if (nextProps.drafts.currentItemId && !this.state.saved) {
      let fields =
        nextProps.drafts.itemsById[nextProps.drafts.currentItemId].values;
      for (var i in fields) {
        if (fields[i].id) {
          sessionStorage.setItem(fields[i].key, fields[i].value);
        }
      }
      let parent_id = this.state.parent_id;
      parent_id =
        nextProps.drafts.itemsById[nextProps.drafts.currentItemId].parent_id;
      this.setState({ saved: true, parent_id });
    }
    // else {
    //   this.state.saved = false;
    // }

    if (
      nextProps.currentItem &&
      nextProps.currentItem.rulesTree &&
      !this.state.rules
    ) {
      const rules = this.loadLocalData(nextProps.currentItem.rulesTree);
      this.setState(
        {
          rules,
          price: nextProps.currentItem.price,
          paidDoc: nextProps.currentItem.payed,
        },
        () => {
          // this.props.setCurrentDocRulesValueChangeFlag(this.state.paths);
          this.progressBarRender();
        }
      );
    }
    this.setState({ alreadyExist: nextProps.alreadyExist });
    if (
      nextProps.attachmentsUrl !== this.props.attachmentsUrl &&
      nextProps.attachmentsUrl !== null &&
      !this.state.attachmentsFromPropsStatus
    ) {
      this.handlePropsAttachmentsUrl(nextProps);
    }
    if (
      nextProps.profile &&
      nextProps.profile.font_family_setting &&
      nextProps.profile.font_size_setting &&
      typeof nextProps.currentItem === "object" &&
      this.refs.preview.refs.documentcontent
    ) {
      if (
        nextProps.profile.font_family_setting !== this.state.fontFamily ||
        nextProps.profile.font_size_setting !== this.state.fontSize
      ) {
        this.initFontSettings();
      }
    }
    if (!_.size(this.state.fieldsOrder)) {
      this.setFieldsOrder(nextProps);
    }
    if (
      nextProps.glossaries &&
      nextProps.glossaries !== this.props.glossaries &&
      !_.size(this.state.expandGlossaries)
    ) {
      this.loadGlossaries(nextProps);
    }
  }

  loadGlossaries = (props = this.props) => {
    if (
      props.glossaries &&
      _.size(props.glossaries) &&
      !_.size(this.state.expandGlossaries)
    ) {
      let temp = {};
      let tempCodeNameToId = {};
      let tempCodeIdToName = {};
      let _glossaries = props.glossaries;
      _.map(_glossaries, (element) => {
        if (element.clauses.length) {
          element.clauses.forEach((clause) => {
            temp[`${clause.name}`] = clause;
            tempCodeNameToId[`${clause.name}`] = `clause_${clause.id}`;
            tempCodeIdToName[`clause_${clause.id}`] = `${clause.name}`;
          });
        }
        if (element.fields.length) {
          element.fields.forEach((field) => {
            temp[`${field.name}`] = field;
            tempCodeNameToId[`${field.name}`] = `field_${field.id}`;
            tempCodeIdToName[`field_${field.id}`] = `${field.name}`;
          });
        }
        if (element.selectors.length) {
          element.selectors.forEach((selector) => {
            temp[`${selector.name}`] = selector;
            tempCodeNameToId[`${selector.name}`] = `selector_${selector.id}`;
            tempCodeIdToName[`selector_${selector.id}`] = `${selector.name}`;
          });
        }
      });
      this.setState(
        {
          expandGlossaries: temp,
          glossariesCodeNameToId: tempCodeNameToId,
          glossariesCodeIdToName: tempCodeIdToName,
        },
        () => {
          this.progressBarRender(undefined, true);
        }
      );
    }
  };

  tryLoadCurrentItem = () => {
    if (
      (_.size(this.props.fields) && !this.state.fieldsLoaded) ||
      (this.state.fieldsLoaded &&
        _.size(this.props.fields) !== this.state.fieldsCount)
    ) {
      let newFields = _.map(this.props.fields, (item) => {
        return item.asMutable ? item.asMutable() : item;
      });
      _.map(this.props.selectors, (item) => {
        newFields.push(item.asMutable ? item.asMutable() : item);
      });
      _.map(this.props.clauses, (item) => {
        newFields.push(item.asMutable ? item.asMutable() : item);
      });

      this.setState({
        fieldsLoaded: true,
        fields: newFields,
        fieldsCount: _.size(this.props.fields),
      });
    }
  };

  checkIsWorkflow = () => {
    let url = this.props.location;
    if (
      getQueryParamStatus(url, "workflowId") &&
      getQueryParamStatus(url, "milestoneId")
    ) {
      this.setState({
        isWorkflow: true,
      });
    }
    // if (
    //   this.props.location &&
    //   this.props.location.query &&
    //   // this.props.location.query.workflowCatId &&
    //   // this.props.location.query.workflowCatId !== "" &&
    //   this.props.location.query.workflowId &&
    //   this.props.location.query.workflowId !== "" &&
    //   this.props.location.query.milestoneId &&
    //   this.props.location.query.milestoneId !== ""
    // ) {
    //   this.setState({
    //     isWorkflow: true,
    //   });
    // }
  };

  checkIsShared = () => {
    if (
      this.props.drafts &&
      _.size(this.props.drafts) &&
      this.props.drafts.currentItemId &&
      _.size(this.props.drafts.itemsById) &&
      this.props.profile &&
      _.size(this.props.profile) &&
      !this.state.isShared
    ) {
      if (
        this.props.profile.id !==
        this.props.drafts.itemsById[this.props.drafts.currentItemId].owner_id
      ) {
        this.setState({
          isShared: true,
        });
      }
    }
  };

  setFieldsOrder = (props = this.props) => {
    let { fieldsOrder } = this.state;
    if (
      props.currentItem &&
      props.currentItem.fields_order &&
      props.currentItem.fields_order !== ""
    ) {
      try {
        let parsed_fields = JSON.parse(props.currentItem.fields_order);
        if (_.size(parsed_fields) && parsed_fields !== fieldsOrder) {
          this.setState({
            fieldsOrder: parsed_fields,
          });
        }
      } catch (error) {
        console.trace("Error in setFieldsOrder", error);
      }
    }
  };

  initFontSettings = () => {
    if (
      this.state.user &&
      !this.state.fontLoaded &&
      this.refs.preview.refs.documentcontent
    ) {
      let userSettings = JSON.parse(this.state.user.settings);
      let documentSettings = userSettings.document_settings;
      let fontFamily = documentSettings.fontfamily;
      let fontSize = documentSettings.fontsize;
      /* To set user doc settings */
      let docFontFamily = this.props.profile.font_family_setting
        ? this.props.profile.font_family_setting
        : fontFamily;
      let docFontSize = this.props.profile.font_size_setting
        ? this.props.profile.font_size_setting
        : fontSize;
      this.setState(
        {
          fontFamily: docFontFamily,
          fontSize: docFontSize,
          fontLoaded: true,
        },
        () => {
          this.initDocSettingsPreview();
        }
      );
    }
  };

  handleProgressBar = () => {
    this.progressBarRender();
  };

  handleOnShowLatestRevisionModal = () => {
    this.setState({ forceBackDrop: "force-backdrop" });
  };

  handlelatestRevisionRedirect = () => {
    let locale = language.getIso2();
    let category = this.props.currentCategory,
      doc = this.props.currentItem,
      latestId = this.props.drafts.latestRevisionId;

    if (category && doc && latestId) {
      // browserHistory.push({`/${locale}/documenten/${makePATHUrl(category.name, category.id)}/${makePATHUrl(doc.name, doc.id)}/${latestId}`});
      //   window.location = "/"+locale+"/documenten/"+makePATHUrl(category.name, category.id)+"/"+makePATHUrl(doc.name, doc.id)+"/"+latestId;
      window.location = `/${locale}/documenten/${makePATHUrl(
        category.name,
        category.id
      )}/${makePATHUrl(doc.name, doc.id)}/${latestId}`;
    }
  };

  isFieldValid = (item, fields = {}) => {
    let valid = true;

    //Check user accept the Risk of the "Required with deny option" fields
    let accepted_risky_fields = [];
    if (sessionStorage.getItem("accept_rwdo_risk") !== null) {
      accepted_risky_fields = JSON.parse(
        sessionStorage.getItem("accept_rwdo_risk")
      );
    }

    // Optional Fields, Always valid, at the same time it should be Empty
    if (
      item.properties &&
      item.properties.isRequired === "0" &&
      item.value === ""
    ) {
      return valid;
    }

    // If there is a profile fetched and Required with deny fields make optional is ON
    // every field is valid but it should be Empty
    if (
      item.properties &&
      item.properties.isRequired === "2" &&
      item.value === "" &&
      this.props.profile &&
      typeof this.props.profile.required_fields !== "undefined" &&
      this.props.profile.required_fields
    ) {
      return valid;
    }

    // Check current field exists in the risky_fields array
    if (
      item.properties &&
      item.properties.isRequired === "2" &&
      item.value === "" &&
      _.includes(accepted_risky_fields, item.name)
    ) {
      return valid;
    }

    if (
      this.state.user &&
      this.state.user.required_fields === "1" &&
      item.value === ""
    ) {
      return valid;
    }

    if (item.value === "" && item.type !== "if") {
      valid = false;
      return valid;
    }

    // check if field is filled
    if (item.value && item.value.toString().match(/\S/)) {
      if (
        item.type === documents.ruleTypes.FIELD &&
        item.properties.type === documents.ruleTypes.field.NUMERIC
      ) {
        // check numeric field condition
        if (item.properties.condition && item.properties.condition.field) {
          let condition = item.properties.condition;
          if (condition.field !== "" && fields[condition.field]) {
            let firstOperand = item.value;
            let secondOperand = fields[condition.field].value;
            if (typeof firstOperand === "string") {
              if (firstOperand === "") {
                firstOperand = 0;
              } else {
                firstOperand = parseFloat(firstOperand);
              }
            }
            if (typeof secondOperand === "string") {
              if (secondOperand === "") {
                secondOperand = 0;
              } else {
                secondOperand = parseFloat(secondOperand);
              }
            }
            if (condition.type === ">") {
              valid = firstOperand > secondOperand ? true : false;
            }
            if (condition.type === ">%") {
              valid =
                firstOperand >
                parseFloat(secondOperand * condition.percentage) / 100
                  ? true
                  : false;
            }
            if (condition.type === "<") {
              valid = firstOperand < secondOperand ? true : false;
            }
            if (condition.type === "<%") {
              valid =
                firstOperand <
                parseFloat(secondOperand * condition.percentage) / 100
                  ? true
                  : false;
            }
          }
          // check if comparation field has been saved to paths
          // let comparationField = item.properties.condition.field
          // if (
          //   this.state.paths &&
          //   this.state.paths.field &&
          //   _.size(this.state.paths.field[comparationField])
          // ) {
          //   // check if the condition is fullfilled
          //   let comparationValue = parseFloat(
          //     _.first(
          //       _.map(this.state.paths.field[comparationField], item => item)
          //     ).node.value
          //   )
          //   let currentValue = parseFloat(item.value)
          //   let percentage = parseFloat(item.properties.condition.percentage)
          //   if (comparationValue) {
          //     if (
          //       item.properties.condition.type === '>' ||
          //       item.properties.condition.type === '>%'
          //     ) {
          //       if (currentValue <= parseFloat((percentage / 100) * comparationValue)) {
          //         valid = false
          //       }
          //     } else if (currentValue > parseFloat((percentage / 100) * comparationValue)) {
          //       valid = false
          //     }
          //   }
          // }
        }
      }
    } else if (item.properties && item.properties.isRequired === 1) {
      valid = false;
    } else if (
      item.properties &&
      item.properties.isRequired === 2 &&
      this.state.require === false
    ) {
      this.requiredDownload();
      valid = false;
    } else if (
      item.properties &&
      item.properties.isRequired === 2 &&
      this.state.require === true
    ) {
      valid = false;
    }

    return valid;
  };

  isFormValid = () => {
    // //debugger
    let hasErrors = {};
    let hasWarnings = {};

    // a form is valid if each of the currently
    // rendered fields are valid
    _.each(this.rendered, (fields, type) => {
      _.each(fields, (field) => {
        if (
          field.properties &&
          field.properties.type === documents.ruleTypes.field.FILE_SELECTOR
        ) {
          //if field type is file-selector then skip this field from validation because attachments are optional
          return;
        }
        if (
          field.properties &&
          field.properties.isRequired === 1 &&
          !this.isFieldValid(field, fields)
        ) {
          this.setState({ require: true });
        } else if (
          field.properties &&
          field.properties.isRequired === 1 &&
          this.isFieldValid(field, fields)
        ) {
          this.setState({ require: false });
        }
      });
      _.each(_.orderBy(fields, "properties.index", "asc"), (field) => {
        if (
          field.properties &&
          field.properties.type === documents.ruleTypes.field.FILE_SELECTOR
        ) {
          //if field type is file-selector then skip this field from validation because attachments are optional
          return;
        }
        if (!this.isFieldValid(field, fields)) {
          hasErrors[field.name] = true;

          if (
            (field.properties &&
              field.properties.isRequired === "1" &&
              field.properties.warningText) ||
            (field.properties &&
              field.properties.isRequired === "2" &&
              field.properties.warningTextRwdo)
          ) {
            hasWarnings[field.name] = true;
          }
        }

        //check condition for numeric values
        // if (field.properties.type === documents.ruleTypes.field.NUMERIC) {
        //   let condition = field.properties.condition;
        //   if ( condition.field && condition.field !== "" && fields[condition.field] ) {
        //     let firstOperand = field.value;
        //     let secondOperand = fields[condition.field].value;
        //     if (typeof firstOperand === "string" && firstOperand !== "") {
        //       firstOperand = parseInt(firstOperand);
        //     }
        //     if (typeof secondOperand === "string" && secondOperand !== "") {
        //       secondOperand = parseInt(secondOperand);
        //     }
        //     if ( condition.type === ">" && firstOperand <= secondOperand ) {
        //       hasErrors[field.name] = true;
        //       if ( field.properties && ( field.properties.warningText || field.properties.warningTextRwdo) ) {
        //         hasWarnings[field.name] = true
        //       }
        //     }
        //     if ( condition.type === ">%" && (firstOperand <= (secondOperand * condition.percentage) / 100) ) {
        //       hasErrors[field.name] = true;
        //       if ( field.properties && ( field.properties.warningText || field.properties.warningTextRwdo) ) {
        //         hasWarnings[field.name] = true
        //       }
        //     }
        //     if ( condition.type === "<" && firstOperand >= secondOperand ) {
        //       hasErrors[field.name] = true;
        //       if ( field.properties && ( field.properties.warningText || field.properties.warningTextRwdo) ) {
        //         hasWarnings[field.name] = true
        //       }
        //     }
        //     if ( condition.type === "<%" && (firstOperand > (secondOperand * condition.percentage) / 100) ) {
        //       hasErrors[field.name] = true;
        //       if ( field.properties && ( field.properties.warningText || field.properties.warningTextRwdo) ) {
        //         hasWarnings[field.name] = true
        //       }
        //     }
        //   }
        // }
      });
    });

    this.setState({ hasWarnings });
    this.setState({ hasErrors });
    _.delay(() => {
      this.triggerWarningsOneByOne();
    }, 500);
    return _.isEmpty(hasErrors);
  };

  triggerWarningsOneByOne = () => {
    if (!_.isEmpty(this.state.hasWarnings)) {
      let warnings = this.state.hasWarnings;
      let first_warning = Object.keys(warnings)[0];
      this.refs.fields.refs[first_warning].click();
    }
  };

  buildLabel = (_label, item = {}) => {
    let {
      expandGlossaries,
      glossariesCodeNameToId,
      glossariesCodeIdToName,
    } = this.state;
    // separate out all selectors and loop through
    let result = strings.getFromBetween.get(_label || "", "[[", "]]");
    if (this.state.paths)
      result.forEach((e) => {
        const reg = new RegExp(e);

        //if multiple codes are responsible for name of a step
        let stepNameOptions = e.split("||");
        if (stepNameOptions.length > 1) {
          stepNameOptions.forEach((element) => {
            let temp_element = element;
            element = element.trim();
            let optionValue = "";
            if (element.indexOf(".") > -1) {
              let firstCode = element.split(".")[0];
              optionValue =
                sessionStorage[firstCode + "_translation"] ||
                sessionStorage[firstCode] ||
                sessionStorage[
                  glossariesCodeNameToId[firstCode] + "_translation"
                ] ||
                sessionStorage[glossariesCodeNameToId[firstCode]];
            } else {
              optionValue =
                sessionStorage[element + "_translation"] ||
                sessionStorage[element] ||
                sessionStorage[
                  glossariesCodeNameToId[element] + "_translation"
                ] ||
                sessionStorage[glossariesCodeNameToId[element]];
            }
            if (optionValue !== "" && !!optionValue) {
              _label = _label.replace(e, optionValue).replace(/\[\[|\]\]/g, "");
            } else if (
              stepNameOptions.indexOf(temp_element) + 1 ===
              stepNameOptions.length
            ) {
              // work out pattern [[*.*_*]] for selects
              if (stepNameOptions[0].trim().indexOf(".") > -1) {
                const field = stepNameOptions[0].trim().split(".")[0];
                const values = stepNameOptions[0]
                  .trim()
                  .split(".")[1]
                  .split("_");
                const selected =
                  sessionStorage[glossariesCodeNameToId[field]] ||
                  sessionStorage[field];
                //if value exist in local storage.
                if (!!selected) {
                  const _keys = ["field", "selector", "if"];
                  _keys.forEach((type) => {
                    if (
                      this.state.paths[type] &&
                      (this.state.paths[type][glossariesCodeNameToId[field]] ||
                        this.state.paths[type][field])
                    ) {
                      let path = null;
                      if (this.state.paths[type][field]) {
                        path = this.state.paths[type][field];
                      } else {
                        path = this.state.paths[type][
                          glossariesCodeNameToId[field]
                        ];
                      }
                      const rule = path[Object.keys(path)[0]].node;

                      if (rule.properties && rule.properties.options) {
                        let valueIndex = rule.properties.options.indexOf(
                          selected
                        );
                        _label = _label
                          .replace(reg, values[valueIndex])
                          .replace(/\[\[|\]\]/g, "");
                      }
                      // let i = 0;
                      // for (var item in rule.options) {
                      //   if (item === selected) break;
                      //   else i++;
                      // }
                      // _label = _label
                      //   .replace(e, values[i])
                      //   .replace(/\[\[|\]\]/g, "");
                    }
                  });
                }
                //if not exist in local storage, then use placeholder.
                else {
                  const _keys = ["field", "selector", "if"];
                  _keys.forEach((type) => {
                    if (
                      this.state.paths[type] &&
                      (this.state.paths[type][glossariesCodeNameToId[field]] ||
                        this.state.paths[type][field])
                    ) {
                      let path = null;
                      if (this.state.paths[type][field]) {
                        path = this.state.paths[type][field];
                      } else {
                        path = this.state.paths[type][
                          glossariesCodeNameToId[field]
                        ];
                      }
                      const rule = path[Object.keys(path)[0]].node;
                      let _placeholder = null;
                      if (rule.properties) {
                        _placeholder = rule.properties.placeholderField;
                      }

                      if (_placeholder) {
                        _label = _label
                          .replace(e, _placeholder)
                          .replace(/\[\[|\]\]/g, "");
                      }
                    } else {
                      _label = _label.replace(e, "").replace(/\[\[|\]\]/g, "");
                    }
                  });
                }
              } else {
                if (expandGlossaries && _.size(expandGlossaries)) {
                  if (
                    expandGlossaries[stepNameOptions[0].trim()] &&
                    expandGlossaries[stepNameOptions[0].trim()].placeholderField
                  ) {
                    _label = _label
                      .replace(
                        e,
                        expandGlossaries[stepNameOptions[0].trim()]
                          .placeholderField
                      )
                      .replace(/\[\[|\]\]/g, "");
                  } else if (
                    expandGlossaries[
                      glossariesCodeIdToName[stepNameOptions[0].trim()]
                    ] &&
                    expandGlossaries[
                      glossariesCodeIdToName[stepNameOptions[0].trim()]
                    ].placeholderField
                  ) {
                    _label = _label
                      .replace(
                        e,
                        expandGlossaries[
                          glossariesCodeIdToName[stepNameOptions[0].trim()]
                        ].placeholderField
                      )
                      .replace(/\[\[|\]\]/g, "");
                  } else {
                    _label = _label
                      .replace(e, "< ## >")
                      .replace(/\[\[|\]\]/g, "");
                  }
                }
              }
            }
          });
        } else {
          //if single code is responsible for name of a step

          // if there's a translation - use it
          const _value =
            sessionStorage[glossariesCodeNameToId[e] + "_translation"] ||
            sessionStorage[glossariesCodeNameToId[e]] ||
            sessionStorage[e] ||
            sessionStorage[e + "_translation"];
          if (_value !== "" && !!_value) {
            _label = _label.replace(reg, _value).replace(/\[\[|\]\]/g, "");
          } else {
            // work out pattern [[*.*_*]] for selects
            if (e.indexOf(".") > -1) {
              const field = e.split(".")[0];
              const values = e.split(".")[1].split("_");
              const selected =
                sessionStorage[glossariesCodeNameToId[field]] ||
                sessionStorage[field];
              //if value exist in local storage.
              if (!!selected) {
                const _keys = ["field", "selector", "if"];
                _keys.forEach((e) => {
                  if (
                    this.state.paths[e] &&
                    this.state.paths[e][glossariesCodeNameToId[field] || field]
                  ) {
                    let path = null;
                    if (this.state.paths[e][glossariesCodeNameToId[field]]) {
                      path = this.state.paths[e][glossariesCodeNameToId[field]];
                    } else {
                      path = this.state.paths[e][field];
                    }
                    // const path = this.state.paths[e][glossariesCodeNameToId[field] || field];
                    const rule = path[Object.keys(path)[0]].node;

                    if (rule.properties && rule.properties.options) {
                      let valueIndex = rule.properties.options.indexOf(
                        selected
                      );
                      _label = _label
                        .replace(reg, values[valueIndex])
                        .replace(/\[\[|\]\]/g, "");
                    }
                    // let i = 0;
                    // for (var item in rule.options) {
                    //   if (item === selected) break;
                    //   else i++;
                    // }
                    // _label = _label
                    //   .replace(reg, values[i])
                    //   .replace(/\[\[|\]\]/g, "");
                  }
                });
              }
              //if not exist in local storage, then use placeholder.
              else {
                const _keys = ["field", "selector", "if"];
                _keys.forEach((e) => {
                  if (
                    this.state.paths[e] &&
                    this.state.paths[e][glossariesCodeNameToId[field] || field]
                  ) {
                    const path = this.state.paths[e][
                      glossariesCodeNameToId[field] || field
                    ];
                    const rule = path[Object.keys(path)[0]].node;
                    let _placeholder = null;
                    if (rule.properties) {
                      _placeholder = rule.properties.placeholderField;
                    }

                    if (_placeholder) {
                      _label = _label
                        .replace(reg, _placeholder)
                        .replace(/\[\[|\]\]/g, "");
                    }
                  } else {
                    _label = _label.replace(reg, "").replace(/\[\[|\]\]/g, "");
                  }
                });
              }
            } else {
              // look for placeholder in ???
              const _path =
                this.state.paths["field"] &&
                this.state.paths["field"][e] &&
                this.state.paths["field"][e][0];
              if (_path) {
                const _placeholder =
                  _path[Object.keys(_path)[0]].node.properties.placeholderField;
                if (_placeholder) {
                  _label = _label
                    .replace(reg, _placeholder)
                    .replace(/\[\[|\]\]/g, "");
                } else {
                  _label = _label
                    .replace(reg, "< ## >")
                    .replace(/\[\[|\]\]/g, "");
                }
              } else {
                // if there's no placeholder then find it in all glossaries
                if (expandGlossaries && _.size(expandGlossaries)) {
                  if (
                    expandGlossaries[e] &&
                    expandGlossaries[e].placeholderField
                  ) {
                    _label = _label
                      .replace(reg, expandGlossaries[e].placeholderField)
                      .replace(/\[\[|\]\]/g, "");
                  } else if (
                    expandGlossaries[glossariesCodeIdToName[e]] &&
                    expandGlossaries[glossariesCodeIdToName[e]].placeholderField
                  ) {
                    _label = _label
                      .replace(
                        reg,
                        expandGlossaries[glossariesCodeIdToName[e]]
                          .placeholderField
                      )
                      .replace(/\[\[|\]\]/g, "");
                  } else {
                    _label = _label
                      .replace(reg, "< ## >")
                      .replace(/\[\[|\]\]/g, "");
                  }
                }
              }
            }
          }
        }
      });
    return _label;
  };

  initHeight = () => {
    // let height = window.innerHeight - 250;
    // this.refs.steps.refs.wrapper.style.height = `${height}px`;
    // this.refs.fields.refs.wrapper.style.maxHeight = `${height}px`;
    // this.refs.preview.refs.wrapper.style.maxHeight = `${height}px`;
    // this.refs.preview.refs.innerWrapperPreview.style.maxHeight = `80vh`;
  };

  setCurrentStep = (step) => {
    const index = this.state.steps.indexOf(step);
    sessionStorage.setItem("index", index);
    if (_.size(this.state.steps) && step) {
      let steps = _.filter(this.state.steps, function (o) {
        return o.haveFields;
      });
      if (_.size(steps) === 0) {
        steps = this.state.steps;
      }
      this.setState({
        currentStep: step,
        isFirstStep: _.first(steps).id === step.id,
        isLastStep: _.last(steps).id === step.id,
      });
    }
  };

  handleStepChange = (step) => {
    let changeStep = false;

    // const current = this.state.steps.indexOf(this.state.currentStep);
    // const next = this.state.steps.indexOf(step);
    // const pagination = current + 1 === next || next < current;

    // disable for now

    // if(!pagination){
    //     console.log('pagination declined');
    //     return;
    // }

    if (step.order < this.state.currentStep.order) {
      changeStep = true;
      this.setState({ in: !this.state.in });
    }
    // check if form is valid
    else if (this.isFormValid()) {
      changeStep = true;
      this.setState({ in: !this.state.in });
    }

    if (changeStep || this.props.draftShareFields) {
      this.setCurrentStep(step);
    }
    this.totalFields = null;
    this.nextStepValidation()

  };

  // available steps changeing here
  handleStepsLoaded = (steps) => {
    this.setState({ steps }, () => {
    let fieldLists = this.refs.fields.getFieldsCount() || [];
      const availableFieldList =  _.filter(fieldLists, (fields) => fields.total)
      const lastStep = sessionStorage.getItem("index");
      const availablefield = _.filter(steps,(step)=> step.id === availableFieldList[0].id)
      let step = lastStep && lastStep > 0 ? steps[lastStep] : _.size(availablefield) > 0 ? availablefield[0] :steps[0];
      this.setCurrentStep(step);
      this.getShareDraftSteps();
    });
  };

  getShareDraftSteps = () => {
    if (
      this.props.drafts &&
      this.props.drafts.shareFields &&
      Object.keys(JSON.parse(this.props.drafts.shareFields)).length > 0 &&
      this.state.steps
    ) {
      let fields = this.props.drafts.fields;
      let shareFields = Object.keys(JSON.parse(this.props.drafts.shareFields));
      let currentStepsId = [];
      let find = false;
      //loop for fetch id's of fields name
      for (const index in fields) {
        if (fields.hasOwnProperty(index)) {
          const element = fields[index];
          if (
            shareFields.includes(element.name) &&
            !currentStepsId.includes(parseInt(element.stepId))
          ) {
            currentStepsId.push(parseInt(element.stepId));
          }
        }
      }
      //set beginning step for selected fields
      this.state.steps.forEach((step, index) => {
        if (currentStepsId.includes(step.id) && !find) {
          this.setState({
            currentStep: step,
          });
          find = true;
        }
      });
    }
  };

  handleFormRender = (rendered) => {
    this.rendered = rendered;
  };

  handleAddComment = (addComment) => {
    let documentComments = { ...this.state.documentComments };
    let userId = {
      id: this.props.profile.id,
      first_name: this.props.profile.first_name,
    };
    addComment["user_id"] = userId;
    if (documentComments[addComment.code]) {
      documentComments[addComment.code] = documentComments[
        addComment.code
      ].concat([addComment]);
    } else {
      documentComments[addComment.code] = [addComment];
    }
    this.setState({
      documentComments,
    });
  };

  handleDeleteAllComment = (field_name) => {
    let documentComments = { ...this.state.documentComments };
    if (this.props.profile.id === this.props.drafts.draftOwnerId) {
      if (documentComments[field_name]) {
        delete documentComments[field_name];
      }
    }
    if (this.props.drafts.draftOwnerId === null) {
      if (documentComments[field_name]) {
        delete documentComments[field_name];
      }
    }
    this.setState({
      documentComments,
    });
  };

  handleUpdateComment = (editedValue, field_name, index) => {
    let documentComments = this.state.documentComments;
    if (!Immutable.isImmutable(this.state.documentComments)) {
      documentComments = Immutable(documentComments);
    }
    documentComments = Immutable.asMutable(documentComments, { deep: true });
    documentComments[field_name][index].comment = editedValue;
    documentComments = Immutable(documentComments);
    this.setState({
      documentComments,
    });
  };

  handleFormChange = (rules, paths, item, scrollY) => {
    this.setState({ rules, paths }, () => {
      this.props.setCurrentDocRulesValueChangeFlag(this.state.paths);
    });
    this.progressBarRender(item);
    let steps = this.state.steps;
    const lastStep = sessionStorage.getItem("index");
    let stepIndex = steps[lastStep] ? lastStep : 0;
    this.setCurrentStep(steps[stepIndex]);
    this.refs.preview.setScrollTo(item.name, scrollY);
    setTimeout(() => {
      let totalFields = _.sumBy(this.state.stepFieldsCount, s => {
      if(s.id !== this.state.currentStep.id)
        return s.total
      return 0;
      });
       if(this.totalFields && totalFields > this.totalFields) {
        NotificationManager.warning(
          `${strings.get("Client.notifications.newStepAdded")}`,
        );
          this.totalFields = totalFields
       }
       else if (!this.totalFields) {
        this.totalFields = totalFields
       }
       else this.totalFields = totalFields
    }, 1000);
   
  };

  handleFormAddCarousel = (rules, name) => {
    this.setState({ rules }, () => {
      this.refs.preview.setScrollTo(name, true);
    });
  };

  handlePrepareItemPreview = (item, showClausePreview) => {
    // console.log('handlePrepareItemPreview', item, showClausePreview);
    let rules = [item];
    return buildContent(rules, showClausePreview);
    // return this.refs.preview.buildContent(rules, showClausePreview);
  };

  handlePrevStep = () => {
    let index = this.getActivePrevStepIndex();
    this.handleStepChange(this.state.steps[index]);
    this.setState({ in: !this.state.in });
  };

  handleNextStep = () => {
    //debugger
    let index = this.getActiveNextStepIndex();
    this.nextStepValidation()
    // const current = sessionStorage.getItem("index");
    this.handleStepChange(this.state.steps[index]);
    this.setState({ in: !this.state.in });
  };

  getActiveNextStepIndex = (index = 0) => {
    let stepIndex =
      index === 0
        ? _.findIndex(
            this.state.steps,
            (step) => step.id === this.state.currentStep.id
          ) + 1
        : index + 1;
    if (
      this.state.steps[stepIndex] &&
      !this.state.steps[stepIndex].haveFields
    ) {
      return this.getActiveNextStepIndex(stepIndex);
    }
    return stepIndex;
  };

  getActivePrevStepIndex = (index = 0) => {
    let stepIndex =
      index === 0
        ? _.findIndex(
            this.state.steps,
            (step) => step.id === this.state.currentStep.id
          ) - 1
        : index - 1;
    if (
      this.state.steps[stepIndex] &&
      !this.state.steps[stepIndex].haveFields
    ) {
      return this.getActivePrevStepIndex(stepIndex);
    }
    return stepIndex;
  };

  handleInputChangeCustomerName = (e) => {
    this.setState({ customerName: e.target.value });
  };

  handleInputChangeDocumentName = (e) => {
    this.setState({ documentName: e.target.value });
  };

  handleInputChangeUserEmail = (e) => {
    this.setState({ userEmail: e.target.value });
  };

  handleInputAddEmail = (e) => {
    e.preventDefault();
    let { shareEmail, shareEmails, shareEmailId } = this.state;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (shareEmail.length > 0 && re.test(shareEmail)) {
      shareEmailId++;
      shareEmails.push({
        id: shareEmailId,
        email: shareEmail,
        value: shareEmailId,
      });
      this.setState({
        shareEmail: "",
        shareEmails: shareEmails,
        shareEmailId: shareEmailId,
        showEmailPushError: false,
      });
    } else {
      this.setState({ showEmailPushError: true });
    }
  };

  handleInputChangeShareDocument = (e) => {
    this.setState({ shareEmail: e.target.value });
  };

  download = async () => {
    let {
      customerName,
      documentName,
      shareEmails,
      userEmail,
      applySettings,
      showLogo,
      fontFamily,
      fontSize,
      attachDocument,
      attachDocumentOnServer,
    } = this.state;
    if (this.isFormValid()) {
      let reservedNames = [
        "Chatra.clientId",
        "Chatra.hostedItems",
        "Chatra.lastPageViewAt",
        "Chatra.referrer",
        "accessToken",
        "language",
        "languages",
        "user",
        "loggedInUserEmail",
        "version",
        "maintenance_allowed_ip",
        "maintenance_cookie_msg",
        "maintenance_desc",
        "maintenance_heading",
        "maintenance_img",
        "maintenance_mode",
        "index",
      ];
      let allFields = sessionStorage;
      let fields = {};
      for (var field in allFields) {
        if (reservedNames.indexOf(field) === -1) {
          fields[field] = allFields[field];
        }
      }

      let innerHtml = this.refs.preview.refs.documentcontent.innerHTML
        .replace(/&nbsp;/g, " ")
        .replace(/<br*[^>]*>/gi, "<br />");
      let attachments = {};
      for (let z = 0; z < attachDocument.length; z++) {
        attachments[`uploadAttachment${z + 1}`] = attachDocument[z].file;
        attachments[`uploadAttachmentCode-${z + 1}`] = attachDocument[z].codeId;
      }
      attachments.uploadAttachmentLength = attachDocument.length;

      let parameters = {
        template: `<div style="font-size: 12px; text-align: justify;">${innerHtml}</div>`,
        templateHtml: innerHtml,
        customerName: customerName,
        name: documentName,
        applySettings: applySettings,
        shareEmails: JSON.stringify(shareEmails),
        userEmail: userEmail,
        showLogo: showLogo,
        fontFamily: fontFamily,
        fontSize: fontSize,
        fields: JSON.stringify(fields),
        attachDocumentOnServer: JSON.stringify(attachDocumentOnServer),
      };
      if (_.size(this.props.userCurrentWorkflow)) {
        parameters.existing_workflow_id = this.props.userCurrentWorkflow.id;
      }

      let allParameters = { ...parameters, ...attachments };
      await this.props.downloadItem(allParameters);
    }
  };

  downloadModal = async () => {
    let { customerName, documentName, shareEmails, userEmail } = this.state;
    let reservedNames = [
      "Chatra.clientId",
      "Chatra.hostedItems",
      "Chatra.lastPageViewAt",
      "Chatra.referrer",
      "accessToken",
      "language",
      "languages",
      "user",
      "loggedInUserEmail",
      "version",
      "maintenance_allowed_ip",
      "maintenance_cookie_msg",
      "maintenance_desc",
      "maintenance_heading",
      "maintenance_img",
      "maintenance_mode",
      "index",
    ];
    let allFields = sessionStorage;
    let fields = {};
    for (var field in allFields) {
      if (reservedNames.indexOf(field) === -1) {
        fields[field] = allFields[field];
      }
    }

    let innerHtml = this.refs.preview.refs.documentcontent.innerHTML;

    await this.props.downloadItem({
      template:
        '<div style="font-size: 12px; text-align: justify;">' +
        this.refs.preview.refs.documentcontent.innerHTML +
        "</div>",
      templateHtml: innerHtml.replace(/&nbsp;/g, ""),
      customerName: customerName,
      name: documentName,
      shareEmails: JSON.stringify(shareEmails),
      userEmail: userEmail,
      fields: JSON.stringify(fields),
    });
  };

  handleDocumentDownload = () => {
    let { userEmail } = this.state;
    let { customerName } = this.state;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.paidDoc && customerName === "") {
      this.setState({ showCustomerNameError: true });
    } else {
      this.setState({ showCustomerNameError: false });
    }

    if (re.test(userEmail)) {
      this.setState({ showError: false });
    } else {
      this.setState({ showError: true });
    }

    _.delay(() => {
      if (!this.state.showError && !this.state.showCustomerNameError) {
        this.download();
        this.refs.documentNameModal.hide();
        if (this.state.sendToOwnerConfirm) {
          this.handleUpdateOwnerDraft();
        }
      }
    }, 100);
  };

  handlerequiredDenyOptionClick = () => {
    this.downloadModal();
    this.refs.requiredDenyOptionModal.hide();
  };

  handleModalHide = () => {
    this.refs.documentNameModal.hide();
  };

  handleCancelClick = () => {
    this.refs.documentNameModal.hide();
  };

  handleDownload = () => {
    // if all fields are filled -> validation is true, show modal download
    // if (this.isFormValid() && this.props.draftShareFields === undefined) {
    //   this.refs.documentNameModal.show();
    // }
    if (this.isFormValid() && !this.state.isShared) {
      //Document can be download either draft is not saved or daft is accessed by a owner
      const stepsValid = this.validateSteps();
      if(stepsValid)  this.refs.documentNameModal.show();
    }
    // this.refs.documentNameModal.show()
  };

  requiredDownload = () => {
    this.refs.requiredDenyOptionModal.show();
  };

  requiredModalHide = () => {
    this.refs.requiredDenyOptionModal.hide();
  };

  requiredCancelClick = () => {
    this.refs.requiredDenyOptionModal.hide();
  };

  handleRemove = (value) => {
    let { shareEmails } = this.state;
    let selected = _.filter(shareEmails, (item) => {
      return item.id !== value;
    });
    this.setState({ shareEmails: selected });
  };

  handleCreateDraft = (names) => {
    let { attachDocument, attachDocumentOnServer, isWorkflow } = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    let reservedNames = [
      "Chatra.clientId",
      "Chatra.hostedItems",
      "Chatra.lastPageViewAt",
      "Chatra.referrer",
      "accessToken",
      "language",
      "languages",
      "user",
      "loggedInUserEmail",
      "version",
      "maintenance_allowed_ip",
      "maintenance_cookie_msg",
      "maintenance_desc",
      "maintenance_heading",
      "maintenance_img",
      "maintenance_mode",
      "index",
    ];
    let allFields = sessionStorage;
    let fields = {};
    for (var field in allFields) {
      if (reservedNames.indexOf(field) === -1) {
        fields[field] = allFields[field];
      }
    }

    let attachments = {};
    for (let z = 0; z < attachDocument.length; z++) {
      attachments[`uploadAttachment${z + 1}`] = attachDocument[z].file;
      attachments[`uploadAttachmentCode-${z + 1}`] = attachDocument[z].codeId;
    }
    attachments.uploadAttachmentLength = attachDocument.length;

    let data = {
      user_id: user.id,
      document_id: this.props.currentItem.id,
      name: names.draftName,
      fields: JSON.stringify(fields),
      selectorType: this.state.selectorType,
      attachDocumentOnServer: JSON.stringify(attachDocumentOnServer),
      workflow_name: names.workflowName,
    };
    if (isWorkflow) {
      // data.workflow_id = this.props.location.query.workflowId;
      data.workflow_id = getQueryParam(this.props.location, "workflowId");
      data.milestone_id = getQueryParam(this.props.location, "milestoneId");
      // data.milestone_id = this.props.location.query.milestoneId;
      data.workflow = true;
      data.templateHtml = this.refs.preview.refs.documentcontent.innerHTML
        .replace(/&nbsp;/g, " ")
        .replace(/<br*[^>]*>/gi, "<br />");
      if (_.size(this.props.userCurrentWorkflow)) {
        data.existing_workflow_id = this.props.userCurrentWorkflow.id;
      }
    }
    data = { ...data, ...attachments };

    this.props.createDraft(data, this.state.documentComments);
  };

  handleCreateDraftAsRole = (draftName) => {
    let { attachDocument, attachDocumentOnServer } = this.state;
    let user = JSON.parse(localStorage.getItem("user"));

    let reservedNames = [
      "Chatra.clientId",
      "Chatra.hostedItems",
      "Chatra.lastPageViewAt",
      "Chatra.referrer",
      "accessToken",
      "language",
      "languages",
      "user",
      "loggedInUserEmail",
      "version",
      "maintenance_allowed_ip",
      "maintenance_cookie_msg",
      "maintenance_desc",
      "maintenance_heading",
      "maintenance_img",
      "maintenance_mode",
      "index",
    ];
    let allFields = sessionStorage;
    let fields = {};
    for (var field in allFields) {
      if (reservedNames.indexOf(field) === -1) {
        fields[field] = allFields[field];
      }
    }

    let attachments = {};
    for (let z = 0; z < attachDocument.length; z++) {
      attachments[`uploadAttachment${z + 1}`] = attachDocument[z].file;
      attachments[`uploadAttachmentCode-${z + 1}`] = attachDocument[z].codeId;
    }
    attachments.uploadAttachmentLength = attachDocument.length;

    let data = {
      user_id: user.id,
      document_id: this.props.currentItem.id,
      name: draftName,
      fields: JSON.stringify(fields),
      selectorType: this.state.selectorType,
      attachDocumentOnServer: JSON.stringify(attachDocumentOnServer),
    };
    data = { ...data, ...attachments };

    // this.props.createDraftAsRole(data)
    this.props.createDraftAsRole(data, this.state.documentComments);
  };

  handleUpdateDraft = (draftName) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let reservedNames = [
      "Chatra.clientId",
      "Chatra.hostedItems",
      "Chatra.lastPageViewAt",
      "Chatra.referrer",
      "accessToken",
      "language",
      "languages",
      "user",
      "loggedInUserEmail",
      "version",
      "maintenance_allowed_ip",
      "maintenance_cookie_msg",
      "maintenance_desc",
      "maintenance_heading",
      "maintenance_img",
      "maintenance_mode",
      "index",
    ];
    let allFields = sessionStorage;
    let fields = {};
    for (var field in allFields) {
      if (reservedNames.indexOf(field) === -1) {
        fields[field] = allFields[field];
      }
    }
    let data = {
      user_id: user.id,
      document_id: this.props.currentItem.id,
      name: draftName,
      fields: JSON.stringify(fields),
    };
    this.props.updateMyDraft(data);
  };

  handleUpdateOwnerDraft = () => {
    let { attachDocument, attachDocumentOnServer, isWorkflow } = this.state;
    let reservedNames = [
      "Chatra.clientId",
      "Chatra.hostedItems",
      "Chatra.lastPageViewAt",
      "Chatra.referrer",
      "accessToken",
      "language",
      "languages",
      "user",
      "loggedInUserEmail",
      "version",
      "maintenance_allowed_ip",
      "maintenance_cookie_msg",
      "maintenance_desc",
      "maintenance_heading",
      "maintenance_img",
      "maintenance_mode",
      "index",
    ];
    let allFields = sessionStorage;
    let fields = {};
    for (var field in allFields) {
      if (reservedNames.indexOf(field) === -1) {
        fields[field] = allFields[field];
      }
    }
    let attachments = {};
    for (let z = 0; z < attachDocument.length; z++) {
      attachments[`uploadAttachment${z + 1}`] = attachDocument[z].file;
      attachments[`uploadAttachmentCode-${z + 1}`] = attachDocument[z].codeId;
    }
    attachments.uploadAttachmentLength = attachDocument.length;

    let id = this.props.drafts.currentItemId.substr(1);
    let parent_id = this.state.parent_id ? this.state.parent_id : id;

    let data = {
      parent_id: parent_id,
      fields: JSON.stringify(fields),
      attachDocumentOnServer: JSON.stringify(attachDocumentOnServer),
    };
    if (isWorkflow) {
      data.templateHtml = this.refs.preview.refs.documentcontent.innerHTML
        .replace(/&nbsp;/g, " ")
        .replace(/<br*[^>]*>/gi, "<br />");
    }
    data = { ...data, ...attachments };

    // this.props.updateOwnerDraft(id, data, this.state.documentType)
    this.props.updateOwnerDraft(
      id,
      data,
      this.state.documentType,
      this.state.documentComments
    );
  };

  handleFreshExist = () => {
    this.props.disableExist();
  };

  addedShareEmail = () => {
    let { shareEmails } = this.state;
    let addedShareEmail = _.map(shareEmails, (user) => {
      return (
        <ActiveLabel
          name={`${user.email}`}
          value={user.id}
          onRemove={this.handleRemove}
          key={user.id}
        />
      );
    });
    return addedShareEmail;
  };

  userEmailInput = () => {
    let { showError } = this.state;
    return (
      <div className={`form-group text-left ${showError ? "has-error" : ""}`}>
        <label className="control-label">
          {strings.get("Client.documentContent.downloadModal.yourEmail")} *
        </label>
        <input
          className="form-control"
          type="text"
          name="userEmail"
          value={this.state.userEmail}
          onChange={this.handleInputChangeUserEmail}
        />
      </div>
    );
  };

  shareDocument = () => {
    let { showEmailPushError } = this.state;
    return (
      <div
        className={`form-group text-left ${
          showEmailPushError ? "has-error" : ""
        }`}
      >
        <label className="control-label ">
          {strings.get("Client.documentContent.downloadModal.shareDocument")}
        </label>
        <input
          className="form-control shareEmail"
          type="text"
          name="shareEmail"
          value={this.state.shareEmail}
          onChange={this.handleInputChangeShareDocument}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.handleInputAddEmail(e);
            }
          }}
        />
        <button
          className="btn btn-secondary add-btn"
          onClick={this.handleInputAddEmail}
        >
          +
        </button>
      </div>
    );
  };

  loadLocalData = (variables, path = [], parent = false) => {
    // let form = [],
    let self = this;
    let tempDocuments = documents;
    if (variables.asMutable) {
      variables = variables.asMutable({ deep: true });
    }
    _.forEach(variables, (node, key) => {
      // check whether or not current node should be rendered
      // and build it accordingly
      if (node.type === tempDocuments.ruleTypes.SELECTOR) {
        if (!!sessionStorage[node.name] && !node.value) {
          node.value = sessionStorage[node.name];
          this.progressBarRender(node, false);
        }
      } else if (node.type === tempDocuments.ruleTypes.CLAUSE) {
        if (!!sessionStorage[node.name] && node.value === "") {
          let clauseValue = sessionStorage[node.name] === "true";
          node.value = clauseValue;
          this.progressBarRender(node, false);
        }
      } else if (node.type === tempDocuments.ruleTypes.FIELD) {
        if (!!sessionStorage[node.name] && !node.value) {
          node.value = sessionStorage[node.name];
          this.progressBarRender(node, false);
        }
      } else if (node.type === tempDocuments.ruleTypes.CAROUSEL) {
        if (!!sessionStorage[node.name] && !node.value) {
          node.value = sessionStorage[node.name];
          this.progressBarRender(node, false);
        }
      }

      // build recursively the rest of the form tree
      if (_.size(node.children)) {
        // if node is clause check if it's checked
        if (node.type === tempDocuments.ruleTypes.CLAUSE && node.value) {
          node.children = self.loadLocalData(
            node.children,
            path.concat(key).concat("children")
          );
        }

        // if node is selector check if a valid option is selected
        else if (node.type === tempDocuments.ruleTypes.SELECTOR) {
          let optionKey = _.findIndex(
            node.children,
            (item) => item.name === node.value
          );

          if (optionKey > -1 && _.size(node.children[optionKey].children)) {
            node.children[optionKey].children = self.loadLocalData(
              node.children[optionKey].children,
              path
                .concat(key)
                .concat("children")
                .concat(optionKey)
                .concat("children"),
              node.type,
              node.value
            );
          }
        }

        // if node is carousel check if a valid slide is selected
        else if (node.type === tempDocuments.ruleTypes.CAROUSEL) {
          let optionKey = _.findIndex(
            node.children,
            (item) => item.name === node.value
          );

          if (optionKey > -1 && _.size(node.children[optionKey].children)) {
            node.children[optionKey].children = self.loadLocalData(
              node.children[optionKey].children,
              path
                .concat(key)
                .concat("children")
                .concat(optionKey)
                .concat("children"),
              node.type,
              node.value
            );
          }
        }
        // if node is html simply parse rest of the children
        else if (node.type === tempDocuments.ruleTypes.HTML) {
          node.children = self.loadLocalData(
            node.children,
            path.concat(key).concat("children")
          );
        }
      }
    });

    return variables;
  };

  handlePropsAttachmentsUrl = (nextProps) => {
    this.setState({ attachmentsFromPropsStatus: true });
    let attachmentsUrl = nextProps.attachmentsUrl;
    this.setState({
      attachDocumentOnServer: attachmentsUrl,
    });
  };

  onTimerUpdate = (duration, time) => {
    let remainingMs = duration - time;
    let remainingTime = this.convertMsToTime(remainingMs);
    this.setState({
      docTimerTimeLeft: remainingTime,
    });
  };

  convertMsToTime = (millis) => {
    let delim = " : ";
    let timer = "";
    if (millis > 0) {
      let hours = Math.floor((millis / (1000 * 60 * 60)) % 60);
      let minutes = Math.floor((millis / (1000 * 60)) % 60);
      let seconds = Math.floor((millis / 1000) % 60);
      if (hours > 0) {
        timer += hours + "h" + delim;
      }
      timer += minutes + "m" + delim + seconds + "s";
      // hours = hours < 10 ? '0' + hours : hours;
      // minutes = minutes < 10 ? '0' + minutes : minutes;
      // seconds = seconds < 10 ? '0' + seconds : seconds;
      return timer;
    }
    return "0h" + delim + "0m" + delim + "0s";
  };
  progressBarRender = (item, setProgress = true) => {
    let progressedFields = this.progressed;
    if (item) {
      let name = item.name;
      // let type = item.type;
      //type "if" means its a radio button from clause
      if (name && item.properties) {
        let stepKey = item.properties.stepId;
        if (!_.has(progressedFields, stepKey)) {
          progressedFields[stepKey] = [];
        }
        let stepProgressedFields = progressedFields[stepKey];
        if (!_.includes(stepProgressedFields, name)) {
          stepProgressedFields.push(name);
          // progressedFields.push(name);
        }
        // If value is empty, then splice that field from array.
        if (!sessionStorage[name] || sessionStorage[name] === "false") {
          //  console.log('PGFname-empty: '+ name);
          stepProgressedFields.splice(stepProgressedFields.indexOf(name), 1);
        }
      }
    }

    this.progressed = progressedFields;
    if (setProgress) {
      let self = this;
      let fieldLists = self.refs.fields.getFieldsCount() || []; // dependant wise field count
      fieldLists.map((f) => {
        self.progressed[f.id] = _.intersection(f.keys, self.progressed[f.id]);
      });
      //update progressed fileds by finding them in session storage
      let clauses = [];
      let steps = this.state.steps;
      if (steps)
        steps.forEach((element) => {
          if (
            element.codes.clauses &&
            Object.keys(element.codes.clauses).length
          ) {
            let clausesArray = Object.values(element.codes.clauses);
            clausesArray.forEach((element) => {
              clauses.push(`clause_${element.id}`);
            });
          }
        });
      fieldLists.map((f) => {
        f.keys.forEach((element) => {
          if (
            sessionStorage.getItem(element) &&
            sessionStorage.getItem(element) !== "" &&
            !self.progressed[f.id].includes(element)
          ) {
            if (clauses.length && clauses.includes(element)) {
              if (sessionStorage.getItem(element) === "true") {
                self.progressed[f.id].push(element);
              }
            } else {
              self.progressed[f.id].push(element);
            }
          }
        });
      });
      let totalCompleted = _(self.progressed)
        .map(function (g, k) {
          let obj = _.find(fieldLists, function (o) {
            return o.id === k;
          });
          if (obj) {
            obj.filled = g.length;
          }
          return g.length;
        })
        .sum();
      // let noOfFields = _.size(self.state.fields); // All Fields count
      let noOfFields = _.sumBy(fieldLists, "total");
      let completedFields = totalCompleted;
      let progressBar = completedFields / noOfFields;
      let docTotalDuration = noOfFields * self.state.docTimerPerFieldTime;
      let docFinishedTime = completedFields * self.state.docTimerPerFieldTime;

      self.onTimerUpdate(docTotalDuration, docFinishedTime);
      self.setState({
        documentProgress: progressBar,
        docTimerDuration: docTotalDuration,
      });
      // console.log('FieldLists -- >', this.state);
      if (fieldLists) {
        self.setState({ stepFieldsCount: fieldLists });
      }
    }
  };

  nextStepValidation = () => {
    let fieldLists =   this.refs.fields.getFieldsCount() || [];
    const currentStepIndex= _.findIndex(fieldLists,items=>items.id==this.state.currentStep.id);
    const previousSteps = _.filter(fieldLists,(fields,index)=>index <= currentStepIndex && fields.total > 0);
    const unFilledFields = _.filter(previousSteps,(preStep)=>preStep.total !== preStep.filled);
    if(_.size(unFilledFields) > 0) {
      const unFilledSteps = _.map(unFilledFields,(fields) =>{
        return _.findIndex(previousSteps,fields) + 1 
      }).join(', ');
      NotificationManager.warning(
        `${unFilledSteps} ${strings.get("Client.notifications.stepsNotFilled")}`,
      );
      return false;
    }
    return true;
  }

  validateSteps = () => {
    let fieldLists = this.refs.fields.getFieldsCount() || [];
    const unFilledFields = _.filter(fieldLists,(fieldData)=>fieldData.total !== fieldData.filled);
    if(_.size(unFilledFields) > 0) {
     const unfilledIndex = _.map(unFilledFields,(unfill)=>{
        return _.findIndex(_.filter(fieldLists,(fields)=>fields.total),unfill)
      });
      const unFilledSteps = unfilledIndex.map(index=>index+1).join(', ')
      NotificationManager.warning(
        `${unFilledSteps} ${strings.get("Client.notifications.stepsNotFilled")}`,
      );
      return false 
    }
    return true
  }

  handlePreviewClick = () => {
    this.togglePreview(true);
    _.delay(() => {
      scrollToComponent(this.refs.preview, { duration: 1500 });
    }, 1000);
  };

  handlePreviewOffClick = () => {
    this.togglePreview(false);
  };

  handleChange = (enabled) => {
    this.setState({ headerDisplay: enabled ? this.displayDocHeader() : null });
    this.setState({ footerDisplay: enabled ? this.displayDocFooter() : null });
    this.setState({ showLogo: enabled ? true : false });
    if (enabled) {
      this.refs.preview.refs.documentBody.style.borderTop = `1px solid #ccc`;
    } else {
      this.refs.preview.refs.documentBody.style.borderTop = ``;
    }
  };

  displayDocHeader = () => {
    let documentSettings = this.props.profile;
    let headerLogoUrl = documentSettings.header_logo_url
      ? documentSettings.header_logo_url
      : null;
    let headerLogoposition = documentSettings.header_logo_position
      ? documentSettings.header_logo_position
      : this.state.headerLogoPosition;
    let headerCompanyTxt = documentSettings.header_company_text
      ? documentSettings.header_company_text
      : null;
    let headerCompanyPosition = documentSettings.header_company_position
      ? documentSettings.header_company_position
      : this.state.headerCompanyPosition;
    let headerCompanyFontsize = documentSettings.header_font_size
      ? documentSettings.header_font_size + "px"
      : this.state.fontSize + "px";

    let logoCnt =
      documentSettings && headerLogoUrl ? (
        <div className="company-logo">
          <img className="img-responsive" src={headerLogoUrl} />
        </div>
      ) : null;

    let addressCnt =
      documentSettings && headerCompanyTxt ? (
        <div
          className="Company-address"
          style={{ fontSize: headerCompanyFontsize }}
        >
          <address>{nl2br(headerCompanyTxt)}</address>
        </div>
      ) : null;

    let logoContent = {
        left: "",
        center: "",
        right: "",
      },
      addressContent = {
        left: "",
        center: "",
        right: "",
      };

    logoContent[headerLogoposition] = logoCnt;
    addressContent[headerCompanyPosition] = addressCnt;

    return logoCnt || addressCnt ? (
      <div className="col-xs-12 doc-header">
        <div className="row">
          <div className="headerpos col-xs-4 text-left">
            {logoContent.left}
            {addressContent.left}
          </div>
          <div className="headerpos col-xs-4 text-center">
            {logoContent.center}
            {addressContent.center}
          </div>
          <div className="headerpos col-xs-4 text-right">
            {logoContent.right}
            {addressContent.right}
          </div>
        </div>
      </div>
    ) : null;
  };

  displayDocFooter = () => {
    let documentSettings = this.props.profile;
    let docFontFamily = documentSettings.font_family_setting
      ? documentSettings.font_family_setting
      : this.state.fontFamily;
    let footerTxt = documentSettings.footer_text
      ? documentSettings.footer_text
      : null;
    let footerPosition = documentSettings.footer_position
      ? documentSettings.footer_position
      : this.state.footerPosition;
    let footerFontsize = documentSettings.footer_font_size
      ? documentSettings.footer_font_size + "px"
      : this.state.fontSize + "px";

    return footerTxt ? (
      <div
        className="col-xs-12  doc-footer"
        style={{
          textAlign: footerPosition,
          fontSize: footerFontsize,
          fontFamily: docFontFamily,
        }}
      >
        {footerTxt}
      </div>
    ) : null;
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.initDocSettingsPreview();
      }
    );
    // setTimeout(() => this.initDocSettingsPreview(), 500)
  };

  initDocSettingsPreview = () => {
    if (this.refs.preview.refs.documentcontent) {
      this.refs.preview.refs.documentcontent.style.fontSize =
        this.state.fontSize + "px";
      this.refs.preview.refs.documentcontent.style.fontFamily = this.state.fontFamily;
    }
  };

  DocumentSettingsOption = () => {
    let documentSettings = this.state.loggedUserEmail ? (
      <div className="form-group">
        <div className="checkbox">
          <label className="control-label">
            <input
              checked={this.state.applySettings}
              onClick={this.handleInputChange}
              type="checkbox"
              name="applySettings"
            />
            {strings.get("Client.documentContent.downloadModal.applySettings")}
          </label>
        </div>
      </div>
    ) : (
      <div />
    );
    return documentSettings;
  };

  logoSettings = () => {
    let logoSettings = this.props.profile.header_logo_url ? (
      <div className="col-sm-3 cust_toggle">
        <div className="settingField">
          <label>{strings.get("Client.settings.showlogo")}</label> <br />
          <Switch
            docsize=""
            enabled={this.state.showLogo}
            onChange={(enabled) => this.handleChange(enabled)}
          />
        </div>
      </div>
    ) : (
      <div />
    );
    return logoSettings;
  };

  togglePreviewPosition = (show) => {
    this.setState({ showPreviewUp: show });

    _.delay(() => {
      if (!this.state.showPreviewUp) {
        this.setState({ fieldsColumn: "col-sm-12" });
      } else {
        this.setState({ fieldsColumn: "col-sm-6" });
      }
    }, 100);
  };

  togglePreview = (show) => {
    if (this.refs.modal1) this.refs.modal1.hide();
    this.setState((prevState) => ({
      showPreview: show,
    }));

    _.delay(() => {
      if (!this.state.showPreview) {
        this.setState({ fieldsColumn: "col-sm-12" });
      } else if (!this.state.showPreviewUp) {
        this.setState({ fieldsColumn: "col-sm-12" });
      } else {
        this.setState({ fieldsColumn: "col-sm-6" });
      }
    }, 100);
  };

  shareEmailMessageSettingLink = () => {
    let locale = language.getIso2();
    let element = this.state.loggedUserEmail ? (
      <div className="form-group">
        <a className="share-email-link" href={`/${locale}/messages/shareemail`}>
          {strings.get("Client.documentContent.accompanyingEmail")}
        </a>
      </div>
    ) : (
      <div />
    );
    return element;
  };

  downloadDocMultiSelected = (highlightKeys, preFilledFields) => {
    this.setState({ highlightKeys, preFilledFields }, () => {
      this.fillForm();
    });
  };

  fillForm = () => {
    let { rules, steps } = this.state;
    steps.forEach((e) => {
      this.fillFields(e.id, rules);
    });
  };

  fillFields = (stepId, variables, path = []) => {
    let form = [],
      self = this,
      tempDocuments = documents;

    _.each(variables, (node, key, arr) => {
      // let element = null;
      if (node.name && this.state.highlightKeys.includes(node.name)) {
        this.progressBarRender(node);
        node.value = this.state.preFilledFields[node.name];
      }
      // build recursively the rest of the form tree
      if (_.size(node.children)) {
        // if node is clause check if it's checked
        if (node.type === tempDocuments.ruleTypes.CLAUSE && node.value) {
          let nodes = self.fillFields(
            stepId,
            node.children,
            path.concat(key).concat("children")
          );
          form = form.concat(nodes);
        }
        // if node is selector check if a valid option is selected
        else if (node.type === tempDocuments.ruleTypes.SELECTOR) {
          let optionKey = _.findIndex(
            node.children,
            (item) => item.name === node.value
          );

          if (optionKey > -1 && _.size(node.children[optionKey].children)) {
            let nodes = self.fillFields(
              stepId,
              node.children[optionKey].children,
              path
                .concat(key)
                .concat("children")
                .concat(optionKey)
                .concat("children")
            );
            form = form.concat(nodes);
          }
        }

        // if node is carousel check if a valid slide is selected
        else if (node.type === tempDocuments.ruleTypes.CAROUSEL) {
          let optionKey = _.findIndex(
            node.children,
            (item) => item.name === node.value
          );

          if (optionKey > -1 && _.size(node.children[optionKey].children)) {
            let nodes = self.fillFields(
              stepId,
              node.children[optionKey].children,
              path
                .concat(key)
                .concat("children")
                .concat(optionKey)
                .concat("children")
            );
            form = form.concat(nodes);
          }
        }

        // if node is html simply parse rest of the children
        else if (node.type === tempDocuments.ruleTypes.HTML) {
          let nodes = self.fillFields(
            stepId,
            node.children,
            path.concat(key).concat("children")
          );
          form = form.concat(nodes);
        }
      }
    });
    // console.log('rule variable', variables);
    this.setState(
      {
        rules: variables,
      },
      () => {
        this.props.setCurrentDocRulesValueChangeFlag(this.state.paths);
      }
    );
  };

  getCustomerName = () => {
    if (this.state.paidDoc) {
      let { showCustomerNameError } = this.state;

      let className = "form-group text-left";
      if (showCustomerNameError) {
        className = "form-group text-left has-error";
      }
      return (
        <div className={className}>
          <label className="control-label">
            {strings.get("Client.documentContent.downloadModal.yourName")} *
          </label>
          <input
            className="form-control"
            type="text"
            name="customerName"
            value={this.state.customerName}
            placeholder={strings.get(
              "Client.documentContent.downloadModal.yourNamePlaceholder"
            )}
            onChange={this.handleInputChangeCustomerName}
          />
        </div>
      );
    }
  };

  getPrice = () => {
    if (this.state.paidDoc) {
      return (
        <div className="form-group text-left">
          <label className="control-label">
            {strings.get("Client.documentContent.downloadModal.price")}{" "}
          </label>
          <div>€ {this.state.price} </div>
        </div>
      );
    }
  };

  getPreviewBlock = (classNamePreview) => {
    return (
      <div className={classNamePreview}>
        <Fade in={this.state.in}>
          <DocumentFormPreview
            ref="preview"
            rules={this.state.rules}
            headerdisplay={this.state.headerDisplay}
            footerdisplay={this.state.footerDisplay}
            buildLabel={this.buildLabel}
            // document={this.props.currentItem}
          />
        </Fade>
      </div>
    );
  };

  getDocSettingsBlock = (classNameDocSettings) => {
    let logoSettings = this.logoSettings();
    let extraButtons = "";
    let buttonHiding = {
      display: this.state.showPreview ? "none" : "block",
    };
    // let buttonShowing = {
    //   display: this.state.showPreview ? "block" : "none",
    // };

    if (classNameDocSettings === "doc-settings-below") {
      extraButtons = (
        <div className="settingSub">
          <div className="col-sm-1 right-col">
            <div className="settingField" style={buttonHiding}>
              <label>{strings.get("Client.documentContent.preview")}</label>
              <br />
              <button
                className="btn btn1 pull-left"
                onClick={(e) => this.togglePreview(true)}
              >
                {strings.get("Client.documentContent.previewOn")}
              </button>
            </div>
          </div>
          <div className="col-sm-2 right-preview">
            <div className="settingField">
              <label>{strings.get("Client.documentContent.preview")}</label>
              <br />
              <button
                className="btn-success btn pull-left desktop-button btn1"
                onClick={(e) => this.togglePreviewPosition(true)}
              >
                {strings.get("Client.documentContent.toTheRight")}
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={classNameDocSettings}>
        {logoSettings}
        <div className="col-sm-3 col-xs-4 col-md-4 lettertype">
          <div className="settingField">
            <label>{strings.get("Client.settings.fontfamily")}</label>
            <br />
            <select
              onChange={this.handleInputChange}
              className="form-control"
              name="fontFamily"
              value={this.state.fontFamily}
            >
              <option value="arial">
                {strings.get("Client.settings.font.arial")}
              </option>
              <option value="Courier New">
                {strings.get("Client.settings.font.courierNew")}
              </option>
              <option value="Georgia">
                {strings.get("Client.settings.font.georgia")}
              </option>
              <option value="tahoma">
                {strings.get("Client.settings.font.tahoma")}
              </option>
              <option value="Times New Roman">
                {strings.get("Client.settings.font.timesNewRoman")}
              </option>
              <option value="verdana">
                {strings.get("Client.settings.font.verdana")}
              </option>
              <option value="Source serif pro">
                {strings.get("Client.settings.font.sourceSerifPro")}
              </option>
            </select>
          </div>
        </div>
        <div className="col-sm-2 col-xs-3 col-md-2 letterweight">
          <div className="settingField">
            <label>{strings.get("Client.settings.fontsize")}</label>
            <br />
            <input
              onChange={this.handleInputChange}
              className="form-control"
              name="fontSize"
              value={this.state.fontSize}
              type="number"
              min="12"
              max="20"
            />
          </div>
        </div>
        {extraButtons}
      </div>
    );
  };

  handleSendToOwnerConfirm = () => {
    this.setState({
      sendToOwnerConfirm: !this.state.sendToOwnerConfirm,
    });
  };

  handleRejectedFiles = (file) => {
    if (file.size > maximumFileSizeToUpload) {
      NotificationManager.warning(
        strings.get("Client.notifications.fileUploadMaxSize"),
        file.name
      );
    } else {
      NotificationManager.warning(
        strings.get("Client.notifications.fileTypeNotSupported"),
        file.name
      );
    }
  };

  handleFileSelect = async (acceptedFiles, rejectedFiles, codeId = "") => {
    if (rejectedFiles.length) {
      for (const iterator of rejectedFiles) {
        this.handleRejectedFiles(iterator);
      }
    }
    let attachments = this.state.attachDocument;
    for (const file of acceptedFiles) {
      let match = false;
      attachments.forEach((attachment) => {
        if (
          attachment.file.name === file.name &&
          attachment.file.size === file.size &&
          attachment.file.type === file.type
        ) {
          match = true;
        }
      });
      let temp = {};
      temp.codeId = codeId;
      temp.file = file;
      if (!match) {
        attachments.push(temp);
        NotificationManager.success(
          strings.get("Client.notifications.added"),
          file.name
        );
      }
    }
    this.setState({
      attachDocument: attachments,
    });
  };

  handleAttachmentsTab = (event) => {
    event.preventDefault();
    let download = document.querySelector("#download");
    let downloadTab = document.querySelector("#downloadTab");
    let attachment = document.querySelector("#attachment");
    let attachmentTab = document.querySelector("#attachmentTab");
    let targetType = event.target.getAttribute("data-type");

    if (targetType === "download") {
      downloadTab.classList.add("borderBottom");
      download.classList.add("in");
      download.classList.add("active");
      attachment.classList.remove("in");
      attachment.classList.remove("active");
      attachmentTab.classList.remove("borderBottom");
    } else {
      attachmentTab.classList.add("borderBottom");
      attachment.classList.add("in");
      attachment.classList.add("active");
      download.classList.remove("in");
      download.classList.remove("active");
      downloadTab.classList.remove("borderBottom");
    }
  };

  handleCategoryRemove = (data) => {
    let attachDocument = this.state.attachDocument;
    attachDocument.splice(data, 1);
    this.setState({ attachDocument }, () => {
      NotificationManager.info(strings.get("Client.notifications.deleted"));
    });
  };
  handleCategoryRemoveServer = (data) => {
    let attachDocumentOnServer = Immutable.asMutable(
      this.state.attachDocumentOnServer
    );
    attachDocumentOnServer.splice(data, 1);
    this.setState({ attachDocumentOnServer });
  };

  setPaths = (paths) => {
    if (!_.size(this.state.paths)) this.setState({ paths });
  };

  checkShareFieldsExistsForUploadedFiles = (item) => {
    if (this.state.saved && !!this.props.profile.id && !this.state.isShared) {
      return false;
    }
    if (this.props.draftShareFields && this.state.saved) {
      let draftShareFields = this.props.draftShareFields
        ? JSON.parse(this.props.draftShareFields)
        : {};
      let is_readyOnly = !draftShareFields.hasOwnProperty(item.codeId);
      return is_readyOnly;
    }
    return false;
  };

  render() {
    if (!checkCookieConsent()) {
      this.props.setCurrentModal("cookieConsentModal");
    }
    let attachDocuments = !this.state.attachDocument.length
      ? null
      : _.map(this.state.attachDocument, (item) => {
          return (
            // <div
            //   key={`document-${item.file.lastModified + Math.random() * 100}`}
            // >
            <ActiveUrlLabel
              name={item.file.name}
              value={this.state.attachDocument.indexOf(item)}
              onRemove={this.handleCategoryRemove}
              key={_.uniqueId()}
              fetchItems={this.props.fetchDraft}
              currentDraftId={
                this.props.drafts && this.props.drafts.currentItemId
                  ? this.props.drafts.currentItemId
                  : "0"
              }
            />
            // </div>
          );
        });
    let attachDocumentOnServer = !this.state.attachDocumentOnServer.length
      ? null
      : _.map(this.state.attachDocumentOnServer, (item) => {
          if (item.url)
            return (
              // <div key={`document-${_.uniqueId()}`}>
              <ActiveUrlLabel
                name={item.url}
                value={this.state.attachDocumentOnServer.indexOf(item)}
                // onRemove={this.handleCategoryRemoveServer}
                onRemove={(data) => {
                  if (!this.checkShareFieldsExistsForUploadedFiles(item)) {
                    this.handleCategoryRemoveServer(data);
                  } else {
                    NotificationManager.warning(
                      "",
                      strings.get("Client.notifications.accessDenied")
                    );
                  }
                }}
                key={_.uniqueId()}
                currentDraftId={
                  this.props.drafts && this.props.drafts.currentItemId
                    ? this.props.drafts.currentItemId
                    : "0"
                }
                fetchItems={this.props.fetchDraft}
                accessDenied={this.checkShareFieldsExistsForUploadedFiles(item)}
              />
              // </div>
            );
        });
    let addedShareEmail = this.addedShareEmail();
    let userEmailInput = this.userEmailInput();
    let shareDocument = this.shareDocument();
    // let documentSettings = this.DocumentSettingsOption();
    // let logoSettings = this.logoSettings();
    let now = Math.round(this.state.documentProgress * 100);
    const displayPreview = !!(
      this.state.showPreviewUp && this.state.showPreview
    );
    const displayPreviewBelow = !!(
      !this.state.showPreviewUp && this.state.showPreview
    );

    let shareEmailMessageSettingLink = this.shareEmailMessageSettingLink();
    let customerName = this.getCustomerName();
    let price = this.getPrice();
    let fileFormats = acceptedFileFormats.split(" ");
    let tooltip = (
      <Tooltip id="uploadFileTypesTooltip">
        <ul>
          {_.map(fileFormats, (format) => {
            return <li key={_.uniqueId()}>{format}</li>;
          })}
        </ul>
      </Tooltip>
    );
    let documentNameModal = (
      <CustomFadeModal
        className="boron-modal form-modal download-modal"
        ref="documentNameModal"
        onHide={this.handleModalHide}
      >
        <div className="close-btn">
          <i
            className="ion-android-cancel clickable"
            onClick={this.handleModalHide}
          />
        </div>
        {customerName}
        <div>
          <div className="with-nav-tabs">
            <div className="panel-heading single-project-nav">
              <ul className="nav nav-tabs">
                <li>
                  <Link
                    to="/"
                    className="borderBottom"
                    onClick={this.handleAttachmentsTab}
                    data-type="download"
                    id="downloadTab"
                  >
                    {strings.get(
                      "Client.documentContent.downloadModal.downloadTabTitle"
                    )}
                  </Link>
                  {/* <a
                    href="javascript:void(0)"
                    onClick={this.handleAttachmentsTab}
                    data-type="download"
                    className="borderBottom"
                    id="downloadTab"
                  >
                    {strings.get(
                      "Client.documentContent.downloadModal.downloadTabTitle"
                    )}
                  </a> */}
                </li>
                <li>
                  {/* <a
                    href="javascript:void(0)"
                    onClick={this.handleAttachmentsTab}
                    data-type="attachment"
                    className=""
                    id="attachmentTab"
                  >
                    {strings.get(
                      "Client.documentContent.downloadModal.attachmentsTabTitle"
                    )}
                  </a> */}
                  <Link
                    to="/"
                    onClick={this.handleAttachmentsTab}
                    data-type="attachment"
                    className=""
                    id="attachmentTab"
                  >
                    {strings.get(
                      "Client.documentContent.downloadModal.attachmentsTabTitle"
                    )}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="panel-body">
              <div className="tab-content">
                <div className="tab-pane fade in active" id="download">
                  <div className="row">
                    <div className="form-group text-left">
                      <label className="control-label">
                        {strings.get(
                          "Client.documentContent.downloadModal.documentName"
                        )}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="documentName"
                        placeholder={strings.get(
                          "Client.documentContent.downloadModal.documentNamePlaceholder"
                        )}
                        value={this.state.documentName}
                        onChange={this.handleInputChangeDocumentName}
                      />
                    </div>
                    {userEmailInput}
                    {shareDocument}
                    {!this.state.attachDocument.length &&
                    !this.state.attachDocumentOnServer.length ? null : (
                      <div className="attachDocumentsList">
                        {attachDocuments}
                        {attachDocumentOnServer}
                      </div>
                    )}
                    <div className="form-group text-left">
                      {addedShareEmail}
                    </div>
                    {/* Hide By Nad.
                          {documentSettings}
                        */}
                    {shareEmailMessageSettingLink}
                    {price}
                    <div style={{ textAlign: "start" }}>
                      <input
                        type="checkbox"
                        name="ownerMsgConfirm"
                        checked={this.state.sendToOwnerConfirm}
                        onChange={this.handleSendToOwnerConfirm}
                      />{" "}
                      &nbsp;{" "}
                      {strings.get(
                        "Client.documentContent.downloadModal.sendToOwnerConfirm"
                      )}
                    </div>
                    <div className="form-actions download-cancel">
                      <button
                        className="btn btn2 mr-20"
                        onClick={this.handleDocumentDownload}
                      >
                        <i className="ion-ios-cloud-download" />
                        {strings.get(
                          "Client.documentContent.downloadModal.btnSave"
                        )}
                      </button>
                      <button
                        className="btn btn4"
                        onClick={this.handleCancelClick}
                      >
                        <i className="ion-android-cancel" />
                        {strings.get(
                          "Client.documentContent.downloadModal.btnCencel"
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="attachment">
                  <div className="dropzone-container">
                    <Dropzone
                      className="dropzone"
                      onDrop={this.handleFileSelect}
                      multiple={true}
                      maxSize={maximumFileSizeToUpload}
                      accept={acceptedFileFormats}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <span>
                            {strings.get(
                              "Client.homePage.workflowForm.uploadMilestoneTitle"
                            )}
                          </span>
                        </div>
                      )}
                    </Dropzone>
                    <OverlayTrigger
                      placement="top"
                      overlay={tooltip}
                      trigger="click"
                      rootClose
                    >
                      <i className="ion-help-circled" />
                    </OverlayTrigger>
                  </div>
                  <div className="attachDocumentsList">
                    {attachDocuments}
                    {attachDocumentOnServer}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomFadeModal>
    );

    let requiredDenyOptionModal = (
      <CustomFadeModal
        className="boron-modal form-modal"
        ref="requiredDenyOptionModal"
        onHide={this.requiredModalHide}
      >
        <div>
          <p className="text-left">{this.props.denyMessage}</p>
        </div>
        <div className="form-actions">
          <button
            className="btn btn-secondary"
            onClick={this.handlerequiredDenyOptionClick}
          >
            {strings.get("Client.documentContent.requiredDenyOptionModal.goIt")}
          </button>
          <button
            className="btn btn-default"
            onClick={this.requiredCancelClick}
          >
            {strings.get("Client.profilePage.cancel")}
          </button>
        </div>
      </CustomFadeModal>
    );

    let needToMoveLatestRevisionModal = (
      <CustomFadeModal
        className="boron-modal form-modal latest-revision"
        ref="needToMoveLatestRevisionModal"
        backdrop={false}
        keyboard={false}
        onShow={this.handleOnShowLatestRevisionModal}
      >
        <div>
          <p className="text-left">
            {strings.get("Client.documentContent.latestRevisionRedirectMsg")}
          </p>
        </div>
        <button
          className="btn btn2"
          onClick={this.handlelatestRevisionRedirect}
        >
          {strings.get("Client.documentContent.latestRevisionMsgBtn")}
        </button>
      </CustomFadeModal>
    );
    return (
      <div className="DocumentForm">
        {documentNameModal}
        {requiredDenyOptionModal}
        {needToMoveLatestRevisionModal}

        <div className={this.state.forceBackDrop} />
        <div className="">
          <div className="custom-progressbar">
            {now === 0 && this.state.currentStep === null ? (
              <SkeletonTheme highlightColor="darkgrey">
                <Skeleton />
              </SkeletonTheme>
            ) : (
              <ProgressBar now={now} label={`${now}%`} />
            )}
          </div>
          {/* This concept is on Hold, So below code was hide.
          <div className="col-sm-12">
            <div className="col-sm-3 col-sm-offset-4">
              <div className="label">
                <span className="text-center timer">
                  <i className="ion-clock"></i>&nbsp;
                  {this.state.docTimerTimeLeft}
                </span>
              </div>
            </div>
          </div>
          */}

          {/* <div className='steps-cont'> */}
          <div>
            <div className="container-row">
              <div className="row">
                <div className="col-sm-12">
                  <DocumentFormSteps
                    ref="steps"
                    glossaries={this.props.glossaries}
                    paths={this.state.paths}
                    steps={this.props.steps}
                    rules={this.state.rules}
                    currentStep={this.state.currentStep}
                    stepFieldsCount={this.state.stepFieldsCount}
                    onChange={this.handleStepChange}
                    onLoaded={this.handleStepsLoaded}
                    stepsOrder={
                      this.props.currentItem &&
                      this.props.currentItem.steps_order
                        ? this.props.currentItem.steps_order
                        : ""
                    }
                    expandGlossaries={this.state.expandGlossaries}
                    glossariesCodeNameToId={this.state.glossariesCodeNameToId}
                    glossariesCodeIdToName={this.state.glossariesCodeIdToName}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="step-forms-field-cont">
            <div className="container-row">
              <div className="row">
                <div
                  className={
                    this.state.fieldsColumn +
                    " preview-" +
                    this.state.showPreview +
                    " up-" +
                    this.state.showPreviewUp
                  }
                >
                  {!auth.isAuthenticated() ? null : (
                    <div
                      className={
                        this.state.showPreviewUp &&
                        !(this.state.showPreviewUp && !this.state.showPreview)
                          ? ""
                          : "col-sm-8"
                      }
                    >
                      <LoadSavedData
                        documentId={
                          this.props.currentItem && this.props.currentItem.id
                            ? this.props.currentItem.id
                            : 0
                        }
                        catName={
                          this.props.currentCategory &&
                          this.props.currentCategory.name
                            ? this.props.currentCategory.name
                            : ""
                        }
                        stepWiseData={true}
                        stepFieldsCount={
                          this.state.stepFieldsCount &&
                          this.state.stepFieldsCount.length
                            ? this.state.stepFieldsCount
                            : []
                        }
                        currentStep={
                          this.state.currentStep &&
                          _.size(this.state.currentStep)
                            ? this.state.currentStep
                            : {}
                        }
                        draftShareFields={
                          this.props.draftShareFields
                            ? this.props.draftShareFields
                            : ""
                        }
                        fields={
                          this.state.fields && this.state.fields.length
                            ? this.state.fields
                            : []
                        }
                        handleDataReUseAccordingStep={
                          this.downloadDocMultiSelected
                        }
                      />
                    </div>
                  )}
                  <DocumentFormFields
                    ref="fields"
                    rules={this.state.rules}
                    rulesOrder={this.state.fieldsOrder}
                    steps={this.state.steps}
                    showPreview={this.state.showPreview}
                    showPreviewUp={this.state.showPreviewUp}
                    glossaries={this.props.glossaries}
                    currentStep={this.state.currentStep}
                    isFirstStep={this.state.isFirstStep}
                    isLastStep={this.state.isLastStep}
                    hasErrors={this.state.hasErrors}
                    document={this.props.currentItem}
                    onChange={this.handleFormChange}
                    onAddCarouselToTemplate={this.handleFormAddCarousel}
                    onRender={this.handleFormRender}
                    onDownload={this.handleDownload}
                    onPrevStep={this.handlePrevStep}
                    onNextStep={this.handleNextStep}
                    onDraftCreate={this.handleCreateDraft}
                    onDraftCreateAsRole={this.handleCreateDraftAsRole}
                    onFreshExist={this.handleFreshExist}
                    onDraftUpdate={this.handleUpdateDraft}
                    onDraftOwnerUpdate={this.handleUpdateOwnerDraft}
                    saved={this.state.saved}
                    alreadyExist={this.state.alreadyExist}
                    logged={!!this.props.profile.id}
                    draftName={
                      this.props.drafts.draftName
                        ? this.props.drafts.draftName
                        : false
                    }
                    draftShareFields={this.props.draftShareFields}
                    onProgressBarRender={this.progressBarRender}
                    handlePreviewClick={this.handlePreviewClick}
                    handlePreviewOffClick={this.handlePreviewOffClick}
                    handlePrepareItemPreview={this.handlePrepareItemPreview}
                    selectorType={this.state.selectorType}
                    highLightStepKeys={this.state.highlightKeys}
                    handleProgressBar={this.handleProgressBar}
                    documentComments={this.state.documentComments}
                    handleAddComment={this.handleAddComment}
                    handleDeleteAllComment={this.handleDeleteAllComment}
                    handleUpdateComment={this.handleUpdateComment}
                    profile={this.props.profile}
                    setPaths={this.setPaths}
                    isWorkflow={this.state.isWorkflow}
                    userCurrentWorkflow={this.props.userCurrentWorkflow}
                    isShared={this.state.isShared}
                    handleFileSelect={this.handleFileSelect}
                    attachDocument={this.state.attachDocument}
                    attachDocumentOnServer={this.state.attachDocumentOnServer}
                    handleCategoryRemoveServer={this.handleCategoryRemoveServer}
                    handleCategoryRemove={this.handleCategoryRemove}
                    expandGlossaries={this.state.expandGlossaries}
                    glossariesCodeNameToId={this.state.glossariesCodeNameToId}
                    glossariesCodeIdToName={this.state.glossariesCodeIdToName}
                    tooltip={tooltip}
                    fetchDraft={this.props.fetchDraft}
                    currentDraftId={
                      this.props.drafts && this.props.drafts.currentItemId
                        ? this.props.drafts.currentItemId
                        : "0"
                    }
                    checkShareFieldsExistsForUploadedFiles={
                      this.checkShareFieldsExistsForUploadedFiles
                    }
                    buildLabel={this.buildLabel}
                    notifyAdmin={this.props.notifyAdmin}
                  />
                </div>
                {displayPreview && (
                  <div
                    // className="col-sm-6 cust_pre_bx sticky-position"
                    className={
                      isMobile
                        ? "col-sm-6 cust_pre_bx"
                        : "col-sm-6 cust_pre_bx sticky-position"
                    }
                    // style={{ position: "sticky", top: 0 }}
                  >
                    <div className="pre_col">
                      <div className="back-to-top text-right">
                        <div className="btn_rw_all">
                          <div className="btn_full preview">
                            <button
                              type="button"
                              className="option-menu opt btn btn-default"
                              onClick={this.showModal}
                            >
                              {strings.get(
                                "Client.documentContent.previewOptions"
                              )}
                            </button>
                          </div>
                          <CustomFadeModal ref="modal1" className="modal_full">
                            <div className="close-btn">
                              <i
                                className="ion-android-cancel clickable"
                                onClick={this.hideModal}
                              />
                            </div>
                            <div className="button_main">
                              <div className="lower_part">
                                {this.getDocSettingsBlock("docSettings")}
                                <div className="settingField mob">
                                  <Link
                                    title="Verberg voorvertoning"
                                    to="/"
                                    className="btn-primary btn pull-right a-doc"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.togglePreview(false);
                                    }}
                                  >
                                    {strings.get(
                                      "Client.documentContent.previewFrom"
                                    )}
                                  </Link>
                                  {/* <a
                                    title="Verberg voorvertoning"
                                    href="JavaScript:Void(0);"
                                    className="btn-primary btn pull-right a-doc"
                                    onClick={(e) => this.togglePreview(false)}
                                  >
                                    {strings.get(
                                      "Client.documentContent.previewFrom"
                                    )}
                                  </a> */}
                                  <Link
                                    title="Verberg voorvertoning"
                                    to="/"
                                    className="btn-success btn pull-right desktop-button a-doc"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.togglePreviewPosition(false);
                                    }}
                                  >
                                    {strings.get(
                                      "Client.documentContent.previewBelow"
                                    )}
                                  </Link>
                                  {/* <a
                                    title="Verberg voorvertoning"
                                    href="JavaScript:Void(0);"
                                    className="btn-success btn pull-right desktop-button a-doc"
                                    onClick={(e) =>
                                      this.togglePreviewPosition(false)
                                    }
                                  >
                                    {strings.get(
                                      "Client.documentContent.previewBelow"
                                    )}
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </CustomFadeModal>
                        </div>
                        <div className="tab_mob">
                          {this.getDocSettingsBlock("docSettings")}
                        </div>
                        <div className="settingField mob">
                          <Link
                            title="Verberg voorvertoning"
                            to="/"
                            className="btn-primary btn pull-right a-doc"
                            onClick={(e) => {
                              e.preventDefault();
                              this.togglePreview(false);
                            }}
                          >
                            {strings.get("Client.documentContent.previewFrom")}
                          </Link>
                          {/* <a
                            title="Verberg voorvertoning"
                            href="JavaScript:Void(0);"
                            className="btn-primary btn pull-right a-doc"
                            onClick={(e) => this.togglePreview(false)}
                          >
                            {strings.get("Client.documentContent.previewFrom")}
                          </a> */}
                          <Link
                            title="Verberg voorvertoning"
                            to="/"
                            className="btn-success btn pull-right desktop-button a-doc"
                            onClick={(e) => {
                              e.preventDefault();
                              this.togglePreviewPosition(false);
                            }}
                          >
                            {strings.get("Client.documentContent.previewBelow")}
                          </Link>
                          {/* <a
                            title="Verberg voorvertoning"
                            href="JavaScript:Void(0);"
                            className="btn-success btn pull-right desktop-button a-doc"
                            onClick={(e) => this.togglePreviewPosition(false)}
                          >
                            {strings.get("Client.documentContent.previewBelow")}
                          </a> */}
                        </div>
                      </div>
                      <div className="prev_blockdv">
                        {this.getPreviewBlock("preview-block")}
                      </div>
                      <div className="desk_vw">
                        {this.getDocSettingsBlock("docSettings")}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {displayPreviewBelow && (
                <div className="row">
                  <div className="col-md-8">
                    {this.getDocSettingsBlock("doc-settings-below")}
                    <div>
                      {this.getPreviewBlock("preview-block preview-below")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DocumentForm.propTypes = {
  denyMessage: PropTypes.string,
  steps: PropTypes.object,
  currentItem: PropTypes.object,
  downloadItem: PropTypes.func,
  profile: PropTypes.object,
  glossaries: PropTypes.any,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    notifyAdmin: (data) => {
      dispatch(settingsAction.notifyAdmin(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentForm);
