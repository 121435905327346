import "whatwg-fetch";
import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

import HomePage from "./containers/home/HomePage";
// import RolesPage from "./containers/roles/RolesPage";
import UserProfilePage from "./containers/profile/UserProfilePage";
import RequiredOptionsPage from "./containers/settings/requiredOptions/RequiredOptionsPage";
import UserDocumentsPage from "./containers/documents/UserDocumentsPage";
import CategoriesListPage from "./containers/categories/CategoriesListPage";
import DocumentListPage from "./containers/documents/DocumentListPage";
import DocumentAlertPage from "./containers/documents/DocumentAlertPage";
import WorkflowCategoriesListPage from "./containers/workflowCategories/WorkflowCategoriesListPage";
import WorkflowListPage from "./containers/workflows/WorkflowListPage";
import WorkflowFormPage from "./containers/workflows/WorkflowFormPage";
import ArticleCategoriesListPage from "./containers/articleCategories/ArticleCategoriesListPage";
import ArticleListPage from "./containers/articles/ArticleListPage";
import ArticlePage from "./containers/articles/ArticlePage";
import Contacts from "./containers/contacts/ContactsPage";
import DraftsPage from "./containers/drafts/DraftsPage";
import MyWorkflowsPage from "./containers/myWorkflow/MyWorkflows";
import SharedDraftsPage from "./containers/sharedDrafts/SharedDraftsPage";
import ShareEmailSettingPage from "./containers/settings/shareEmail/ShareEmailSettingPage";
import ShareDraftInstructionPage from "./containers/settings/shareDraft/ShareDraftInstructionPage";
import AlertsPage from "./containers/settings/alerts/AlertsPage";
import MaintenancePage from "./containers/home/MaintenancePage";
import DonationPage from "./containers/home/DonationPage";
import PaymentPage from "./containers/home/PaymentPage";
import DocumentFormPageAuth from "./containers/documents/DocumentFormPageAuth";
import RoleDocumentFormPageAuth from "./containers/documents/RoleDocumentFormPageAuth";
import VerifyEmail from "./containers/verifyEmail/VerifyEmail";

export const Routes = () => (
  <Switch>
    <Route exact path="/linkedin" component={LinkedInPopUp} />
    <Route exact path="/verifyEmail/:token" component={VerifyEmail} />
    <Route exact path="/approveEmail/:token" component={VerifyEmail} />
    <Route exact path="/maintenance/:id" component={MaintenancePage} />
    <Route exact path="/donation-success" component={DonationPage} />
    <Route exact path="/payment-success" component={PaymentPage} />
    <Route exact path="/" component={HomePage} />
    <Route exact path="/:locale" component={HomePage} />
    <Route exact path="/resetPassword/:token" component={HomePage} />
    {/* <Route exact path="/:locale/roles" component={RolesPage} /> */}
    <Route exact path="/:locale/profile" component={UserProfilePage} />
    <Route exact path="/:locale/mydocuments" component={UserDocumentsPage} />
    <Route exact path="/:locale/settings" component={RequiredOptionsPage} />
    <Route exact path="/:locale/documenten" component={CategoriesListPage} />
    <Route
      exact
      path="/:locale/documenten/:catName-:catId"
      component={DocumentListPage}
    />
    <Route
      exact
      path="/:locale/documenten/:catName-:catId/:docName-:docId/alert/:id"
      component={DocumentAlertPage}
    />
    <Route
      exact
      path="/:locale/documenten/:catName-:catId/:docName-:docId/:id?/:type?"
      component={DocumentFormPageAuth}
    />
    <Route
      exact
      path="/:locale/role/documenten/:catName-:catId/:docName-:docId/:id?/:type?"
      component={RoleDocumentFormPageAuth}
    />
    <Route
      exact
      path="/:locale/workflow"
      component={WorkflowCategoriesListPage}
    />
    <Route
      exact
      path="/:locale/workflows/:catName-:catId"
      component={WorkflowListPage}
    />
    ,
    <Route
      exact
      path="/:locale/workflows/:catName-:catId/:workflowName-:workflowId/:id?/:type?"
      component={WorkflowFormPage}
    />
    <Route
      exact
      path="/:locale/articles"
      component={ArticleCategoriesListPage}
    />
    <Route
      exact
      path="/:locale/articles/:artcatSlug-:artcatId"
      component={ArticleListPage}
    />
    <Route exact path="/:locale/article/:articleSlug" component={ArticlePage} />
    <Route exact path="/:locale/contact" component={Contacts} />,
    <Route exact path="/:locale/drafts" component={DraftsPage} />,
    <Route exact path="/:locale/myworkflows" component={MyWorkflowsPage} />,
    <Route exact path="/:locale/shareddrafts" component={SharedDraftsPage} />
    <Route
      exact
      path="/:locale/messages/shareemail"
      component={ShareEmailSettingPage}
    />
    <Route
      exact
      path="/:locale/messages/sharedraft"
      component={ShareDraftInstructionPage}
    />
    <Route exact path="/:locale/messages/alerts" component={AlertsPage} />
    <Redirect from="*" to="/" />
  </Switch>
);
