import Immutable from "seamless-immutable";
import { types } from "./actions";
import _ from "lodash";

const initialState = Immutable({
  filters: {
    searchTerm: "",
    categoryId: "",
    savedWorkflowId: "",
    fetchOnlyWorkflowDraft: false,
    type: "drafts",
  },
  pagination: {
    currentPage: 1,
    previousPage: 1,
    pageSize: 10,
    totalPages: 1,
  },
  sorter: {
    column: "created_at",
    descending: true,
  },
  currentItemId: null,
  latestRevisionId: null,
  draftOwnerId: null,
  stats: null,
  itemsById: {},
  idsByPage: {
    _1: [],
  },
  fields: {},
  fieldsOrder: {},
  selectors: {},
  clauses: {},
  steps: {},
});

// Save draft to store
function fetchDraftDone(state, payload) {
  let newState = {
    itemsById: {},
  };
  if (payload.item) {
    newState["currentItemId"] = "_" + payload.item.id;
    newState["draftName"] = payload.item.name;
    newState["attachmentsUrl"] = payload.item.attachmentsUrl;
    newState["itemsById"]["_" + payload.item.id] = payload.item;
    newState["shareFields"] = payload.item.share_fields;
    newState["latestRevisionId"] = payload.item.latestrevision_id;
    newState["draftOwnerId"] = payload.item.owner_id;
    newState["draftComments"] = payload.item.comments;
  } else {
    newState["currentItemId"] = newState["latestRevisionId"] = null;
    newState["itemsById"] = [];
  }
  return state.merge(newState, { deep: true });
}

// Save all drafts to store
function fetchAllDraftsDone(state, payload) {
  let newState = {
    pagination: {
      totalPages: payload.totalPages,
    },
    itemsById: {},
    idsByPage: {},
  };
  newState["idsByPage"]["_" + state.pagination.currentPage] = [];
  _.map(payload.items, (item) => {
    newState["itemsById"]["_" + item.id] = item;
    newState["idsByPage"]["_" + state.pagination.currentPage].push(item.id);
  });
  return state.merge(newState, { deep: true });
}

function removeItem(state, payload) {
  let newState = {
    itemsById: {},
  };
  _.map(state.itemsById, (item) => {
    if (item) {
      newState["itemsById"]["_" + item.id] =
        payload.id === item.id ? undefined : item;
    }
  });
  let result = state.merge(newState, { deep: true });
  return result;
}

function switchToUpdate(state, payload) {
  let newState = {
    saved: true,
    currentItemId: "_" + payload.item.id,
    itemsById: {},
  };
  newState["itemsById"]["_" + payload.item.id] = payload.item;
  let result = state.merge(newState, { deep: true });
  return result;
}

function callUpdate(state) {
  let newState = {
    alreadyExist: true,
  };
  let result = state.merge(newState, { deep: true });
  return result;
}

function disableExist(state) {
  let newState = {
    alreadyExist: false,
  };
  let result = state.merge(newState, { deep: true });
  return result;
}

function clearAllDrafts(state) {
  let newState = {
    itemsById: {},
    currentItemId: false,
    draftName: "new draft",
  };
  _.map(state.itemsById, (item) => {
    if (item) {
      newState["itemsById"]["_" + item.id] = undefined;
    }
  });
  let result = state.merge(newState, { deep: true });
  return result;
}

// Clear cached info
function clearCache(state) {
  return state.merge({
    filters: state.filters,
    sorter: state.sorter,
    pagination: {
      currentPage: 1,
      previousPage: 1,
      pageSize: 10,
      totalPages: 1,
    },
    itemsById: {},
    idsByPage: {
      _1: [],
    },
  });
}
// Clear share fields cached info
function clearShareFieldsCache(state) {
  return state.merge({
    fields: {},
    fieldsOrder: {},
    selectors: {},
    clauses: {},
    steps: {},
  });
}

function validateItemDone(state, payload) {
  let newState = {
    fields: {},
    fieldsOrder: {},
    selectors: {},
    clauses: {},
    steps: {},
  };
  newState["fields"] = payload.fields;
  newState["fieldsOrder"] = payload.fieldsOrder;
  newState["clauses"] = payload.clauses;
  newState["selectors"] = payload.selectors;
  _.map(payload.steps, (step) => {
    newState["steps"]["_" + step.id] = step;
  });
  return state.merge(newState, { deep: true });
}

function validateItemShareFieldsDone(state, payload) {
  let newState = {
    shareFields: {},
  };
  newState["shareFields"] = payload.item.share_fields;
  return state.merge(newState, { deep: true });
}

// Set category id filter
function setCategoryId(state, payload) {
  return state.merge(
    {
      filters: {
        categoryId: payload.id,
      },
    },
    { deep: true }
  );
}

// Set user saved workflow id filter
function setSavedWorkflowId(state, payload) {
  return state.merge(
    {
      filters: {
        savedWorkflowId: payload.id,
      },
    },
    { deep: true }
  );
}

// Set user saved workflow id filter
function setFetchOnlyWorkflowDraftStatus(state, payload) {
  return state.merge(
    {
      filters: {
        fetchOnlyWorkflowDraft: payload.status,
      },
    },
    { deep: true }
  );
}

// Set current search term
function setSearchTerm(state, payload) {
  return state.merge(
    {
      filters: {
        searchTerm: payload.searchTerm,
      },
    },
    { deep: true }
  );
}

// Set current page for items list
function setCurrentPage(state, payload) {
  return state.merge(
    {
      pagination: {
        currentPage: payload.page,
        previousPage: state.pagination.currentPage,
      },
    },
    { deep: true }
  );
}

// Set the id of current item
function setCurrentItemId(state, payload) {
  return state.merge({
    currentItemId: payload.id,
  });
}

// Set drafts type
function setDraftsType(state, payload) {
  return state.merge(
    {
      filters: {
        type: payload.type,
      },
    },
    { deep: true }
  );
}

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_DRAFT_DONE:
      return fetchDraftDone(state, action.payload);
    case types.FETCH_ALL_DRAFTS_DONE:
      return fetchAllDraftsDone(state, action.payload);
    case types.FETCH_DELETE_DRAFT_DONE:
      return removeItem(state, action.payload);
    case types.CREATE_DRAFT_DONE:
      return switchToUpdate(state, action.payload);
    case types.DRAFT_ALREADY_EXIST:
      return callUpdate(state);
    case types.DISABLE_EXIST:
      return disableExist(state);
    case types.CLEAR_DRAFTS:
      return clearAllDrafts(state);
    case types.VALIDATE_ITEM_DONE:
      return validateItemDone(state, action.payload);
    case types.FETCH_DRAFT_SHARE_FIELDS_DONE:
      return validateItemShareFieldsDone(state, action.payload);
    case types.CLEAR_CACHE:
      return clearCache(state);
    case types.CLEAR_SHARE_FIELDS_CACHE:
      return clearShareFieldsCache(state);
    case types.SET_CATEGORY_ID:
      return setCategoryId(state, action.payload);
    case types.SET_SAVED_WORKFLOW_ID:
      return setSavedWorkflowId(state, action.payload);
    case types.SET_FETCH_ONLY_WORKFLOW_DRAFTS:
      return setFetchOnlyWorkflowDraftStatus(state, action.payload);
    case types.SET_SEARCH_TERM:
      return setSearchTerm(state, action.payload);
    case types.SET_CURRENT_PAGE:
      return setCurrentPage(state, action.payload);
    case types.SET_CURRENT_ITEM_ID:
      return setCurrentItemId(state, action.payload);
    case types.SET_DRAFTS_TYPE:
      return setDraftsType(state, action.payload);
    default:
      return state;
  }
}
