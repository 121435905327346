import api from "../../services/api";
import language from "../../services/language";
import _ from "lodash";

import * as exceptionsActions from "../exceptions/actions";
import * as documentsSelectors from "./selectors";
import * as settingsActions from "../settings/actions";
import { NotificationManager } from "react-notifications";
import strings from "../../services/strings";

import { viewModals } from "../../helpers";

import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

export const types = {
  FETCH_ALL_ITEMS_DONE: "documents.FETCH_ALL_ITEMS_DONE",
  FETCH_ITEMS_DONE: "documents.FETCH_ITEMS_DONE",
  FETCH_ITEM_DONE: "documents.FETCH_ITEM_DONE",
  FETCH_STATS_DONE: "categories.FETCH_STATS_DONE",
  SET_SORTER: "documents.SET_SORTER",
  SET_SEARCH_TERM: "documents.SET_SEARCH_TERM",
  SET_CATEGORY_ID: "documents.SET_CATEGORY_ID",
  FETCH_PLACEHOLDER_DONE: "documents.FETCH_PLACEHOLDER_DONE",
  FETCH_DENY_MESSAGE: "documents.FETCH_DENY_MESSAGE",
  SET_CURRENT_PAGE: "documents.SET_CURRENT_PAGE",
  SET_PAGE_SIZE: "documents.SET_PAGE_SIZE",
  SET_CURRENT_ITEM_ID: "documents.SET_CURRENT_ITEM_ID",
  TOGGLE_SORTER: "documents.TOGGLE_SORTER",
  CLEAR_CACHE: "documents.CLEAR_CACHE",
  SET_CURRENT_DOCUMENT_RULES: "documents.SET_CURRENT_DOCUMENT_RULES",
  CLEAR_PAGINATION: "documents.CLEAR_PAGINATION",
};

export function setCurrentDocRulesValueChangeFlag(paths) {
  return {
    type: types.SET_CURRENT_DOCUMENT_RULES,
    payload: {
      paths,
    },
  };
}

export function setCategoryId(id) {
  return {
    type: types.SET_CATEGORY_ID,
    payload: {
      id,
    },
  };
}

export function sendEmails(data) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setScreenLoading(true));
      let params = new Map();
      _.map(data, (value, key) => {
        if (key !== "to") {
          params.set(key, value);
        } else {
          data.to.forEach((e, i) => {
            params.set(`to[${i}]`, e);
          });
        }
      });
      // PUT request to API
      await api.post(`/shareByEmail`, params);
      NotificationManager.success(
        strings.get("Exceptions.notifications.draftShared")
      );
    } catch (e) {
      NotificationManager.error(strings.get("Exceptions.notifications.error"));
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setScreenLoading(false));
    }
  };
}

export function setCurrentPage(page) {
  return {
    type: types.SET_CURRENT_PAGE,
    payload: {
      page,
    },
  };
}

export function setPageSize(pageSize) {
  return {
    type: types.SET_PAGE_SIZE,
    payload: {
      pageSize,
    },
  };
}
export function clearPagination() {
  return {
    type: types.CLEAR_PAGINATION,
  };
}

export function setCurrentItemId(id) {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id,
    },
  };
}

export function setShareDraftId(id) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("draft_id", id);
      await api.put(`/addsharedraft`, params);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function unsetCurrentItemId() {
  return {
    type: types.SET_CURRENT_ITEM_ID,
    payload: {
      id: null,
    },
  };
}

export function toggleSorter(column) {
  return {
    type: types.TOGGLE_SORTER,
    payload: {
      column,
    },
  };
}

export function setSorter(sorter) {
  return {
    type: types.SET_SORTER,
    payload: {
      sorter,
    },
  };
}

export function setSearchTerm(searchTerm) {
  return {
    type: types.SET_SEARCH_TERM,
    payload: {
      searchTerm,
    },
  };
}

export function clearCache() {
  return {
    type: types.CLEAR_CACHE,
  };
}

export function fetchAllItems() {
  return async (dispatch, getState) => {
    try {
      let params = new Map();
      params.set("language_id", language.get());
      params.set("expand", "downloads");
      params.set("sort_by", "name");

      let items = await api.get("/documents", params);
      dispatch(clearCache());
      dispatch({
        type: types.FETCH_ALL_ITEMS_DONE,
        payload: {
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItemValues(id, type, callback = () => {}) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("id", id);
      params.set("type", type);

      // GET request from API
      api.get(`/documents/alert`, params).then((item) => {
        callback(item);
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function getPlaceholder() {
  return async (dispatch, getState) => {
    try {
      let params = new Map();
      params.set("language_id", language.get());

      let item = await api.get("/settings/search_placeholder", params);

      if (item) {
        dispatch({
          type: types.FETCH_PLACEHOLDER_DONE,
          payload: {
            item,
          },
        });
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function getDenyMessage() {
  return async (dispatch, getState) => {
    try {
      let item = await api.get("/settings/deny_message");
      //dispatch(clearCache());

      if (item) {
        dispatch({
          type: types.FETCH_DENY_MESSAGE,
          payload: {
            item,
          },
        });
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItems(deleteCache = false) {
  return async (dispatch, getState) => {
    let state = getState();
    try {
      // Set additional params
      let params = new Map();
      let filters = documentsSelectors.getFilters(state);
      let sorter = documentsSelectors.getSorter(state);
      let pagination = documentsSelectors.getPagination(state);

      params.set("language_id", language.get());
      params.set("expand", "downloads");
      params.set("name~", filters.searchTerm);
      params.set("category_id", filters.categoryId ? filters.categoryId : "");

      params.set("page_number", deleteCache ? 1 : pagination.currentPage);
      params.set("sort_by", sorter.column);
      params.set("sort_desc", sorter.descending);
      params.set("page_size", pagination.pageSize);

      // GET request from API
      let [response, items] = await api.get("/documents", params, true);

      // Clear cache if deleteCache is enabled
      if (deleteCache) {
        dispatch(clearCache());
      }

      dispatch({
        type: types.FETCH_ITEMS_DONE,
        payload: {
          totalPages: parseInt(response.headers.get("X-Total-Pages")),
          items,
        },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchItem(id, type = "", userWorkflowId = undefined) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setScreenLoading(true));
      let params = new Map();
      params.set("withRulesTree", "true");
      params.set("expand", "downloads");
      if (userWorkflowId) {
        params.set("userWorkflowId", userWorkflowId);
      }
      params.set("published", "1");
      if (type !== "") {
        params.set("type", type);
      }
      // GET request from API
      let item = await api.get(`/documents/${id}`, params);
      let ms = 8000;
      let redirect = false;
      if (!_.size(item)) {
        NotificationManager.warning(
          strings.get("Client.notifications.noData"),
          "",
          ms
        );
        redirect = true;
      } else if (item.error) {
        if (item.error === viewModals.HIDDEN_CATEGORY_MESSAGE) {
          dispatch(
            settingsActions.setCurrentModal(viewModals.HIDDEN_CATEGORY_MESSAGE)
          );
          redirect = true;
        } else if (item.error === viewModals.LOG_IN_MESSAGE) {
          dispatch(settingsActions.setCurrentModal(viewModals.LOG_IN_MESSAGE));
        } else if (
          item.error === viewModals.CATEGORY_CUSTOM_USER_ACCESS_MESSAGE
        ) {
          dispatch(
            settingsActions.setCurrentModal(
              viewModals.CATEGORY_CUSTOM_USER_ACCESS_MESSAGE
            )
          );
          redirect = true;
        } else {
          NotificationManager.warning(
            item.error,
            strings.get("Client.notifications.noData"),
            ms
          );
          redirect = true;
        }
      } else {
        dispatch({
          type: types.FETCH_ITEM_DONE,
          payload: {
            item,
          },
        });
      }

      if (redirect) {
        _.delay(() => {
          window.history.back();
        }, ms);
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setScreenLoading(false));
    }
  };
}

export function createItem(data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      await api.post("/documents", params);
      browserHistory.push(`/documents`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function updateItem(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // PUT request to API
      await api.put(`/documents/${id}`, params);
      browserHistory.push(`/documents`);

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function uploadItemLogo(id, file) {
  return async (dispatch) => {
    try {
      let params = new Map();
      params.set("file", file);
      // POST request to API
      await api.postFiles(`/documents/${id}/image`, params);

      dispatch(fetchItem(id));
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function createItemWithLogo(data, file) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      let item = await api.post("/documents", params);
      browserHistory.push(`/documents`);

      params = new Map();
      params.set("file", file);
      // POST request to API for Upload
      await api.postFiles(`/documents/${item.id}/image`, params);

      dispatch(fetchItem(item.id));
      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}
export function downloadItem(id, data) {
  return async (dispatch) => {
    try {
      let params = new Map();
      _.map(data, (value, key) => {
        params.set(key, value);
      });
      // POST request to API
      dispatch(settingsActions.setScreenLoading(true));
      try {
        let payload = await api.post(`/documents/${id}/download`, params);
        _.delay(() => {
          let a = document.createElement("a");
          a.href = payload.url;
          a.target = payload.target;
          a.click();
        }, 100);
      } catch (error) {
        NotificationManager.error(
          strings.get("Client.notifications.error"),
          "",
          5000
        );
      } finally {
        dispatch(settingsActions.setScreenLoading(false));
      }

      dispatch(exceptionsActions.clear());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function deleteItem(id) {
  return async (dispatch) => {
    try {
      // DELETE request to API
      await api.delete("/documents/" + id);
      dispatch(fetchItems());
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function fetchStats() {
  return async (dispatch) => {
    try {
      let items = await api.get("/stats");

      dispatch({
        type: types.FETCH_STATS_DONE,
        payload: { items },
      });
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    }
  };
}

export function matchDraftWithDoc(draftId, docIds, callbackFunc = () => {}) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setScreenLoading(true));
      let params = new Map();
      params.set("draftId", draftId);
      params.set("docId", docIds);

      // PUT request to API
      let result = await api.get(`/documents/matchedDraftPercentage`, params);
      callbackFunc(result);
    } catch (e) {
      NotificationManager.error(strings.get("Exceptions.notifications.error"));
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setScreenLoading(false));
    }
  };
}

export function processDraftDataWithDocs(draftId, docIds) {
  return async (dispatch) => {
    try {
      dispatch(settingsActions.setScreenLoading(true));
      let params = new Map();
      params.set("draftId", draftId);
      params.set("docIds", docIds);

      // GET request to API
      try {
        let payload = await api.get(
          `/documents/processDraftDataWithDocs`,
          params
        );
        if(payload.url){
          _.delay(() => {
            let a = document.createElement("a");
            a.href = payload.url;
            a.target = payload.target;
            a.click();
          }, 500);
        }
        if(payload.error === "DRAFT DO NOT HAVE ANY DATA"){
          NotificationManager.info(
            strings.get("Exceptions.notifications.blankDraft"),
            "",
            4000
          );
        }

      } catch (error) {
        NotificationManager.error(
          strings.get("Exceptions.notifications.error"),
          "",
          5000
        );
      }
    } catch (e) {
      dispatch(exceptionsActions.process(e));
    } finally {
      dispatch(settingsActions.setScreenLoading(false));
    }
  };
}
