import React, { Component } from "react";
import { OldSocialLogin as SocialLogin } from "react-social-login";
import LinkedIn from 'react-linkedin-login-oauth2'
import api from '../../services/api'
import config from "../../config/environments";
import _ from 'lodash'
class SocialButton extends Component {

  handleFailure = (err) => {
    console.log('linked in error --  > ', err);
  }

  handleSuccess = (data) => {
    if(data.code)
    this.props.handleLinkedin(data.code, this.callback)
  }

  callback = (data) => {
    if(data)
      this.props.handleSocial(data);
  }

  handleClick = (click) => {
    console.log('linked in handleClick --  > ', click);
  }

  render() {
    return (
      <div>
        <SocialLogin
          provider="facebook"
          appId={config.FACEBOOK_APP_ID}
          callback={this.props.handleSocial}
        >
          <button className="socialLoginBtn socialLoginBtn--facebook">
            <div className="icon-div">
              <i className="ion-social-facebook" />
            </div>
            <div className="icon-text-div">{this.props.fbBtnLabel}</div>
          </button>
        </SocialLogin>

        {/*
      <SocialLogin
        provider='google'
        appId={config.GOOGLE_APP_ID}
        callback={this.props.handleSocial}
      >
        <button className="socialLoginBtn socialLoginBtn--google">
          <div className="icon-div"><i className="ion-social-google"/></div>
          <div className="icon-text-div">{this.props.googleBtnLebel}</div>
        </button>
      </SocialLogin>
      */}
      <LinkedIn 
          // callback={this.props.handleSocial}
          
          className="socialLoginBtn"
          text='LinkedIn'
          clientId={config.LINKEDIN_APP_ID}
          onFailure={this.handleFailure}
          onSuccess={this.handleSuccess}
          // onSuccess={this.props.handleSocial}
          // supportIE
          redirectUri={`${window.location.origin}/linkedin`}
          scope="r_liteprofile,r_emailaddress"
          state={_.uniqueId()}
          // redirectPath='/linkedin'
      >
          <div className="socialLoginBtn socialLoginBtn--linkedin">
            <div className="icon-div">
              <i className="ion-social-linkedin" />
            </div>
            <div className="icon-text-div">{this.props.linkedinBtnLabel}</div>
          </div>
        </LinkedIn>
        {/* <SocialLogin
          provider="linkedin"
          appId={config.LINKEDIN_APP_ID}
          callback={this.props.handleSocial}
        >
          <button className="socialLoginBtn socialLoginBtn--linkedin">
            <div className="icon-div">
              <i className="ion-social-linkedin" />
            </div>
            <div className="icon-text-div">{this.props.linkedinBtnLabel}</div>
          </button>
        </SocialLogin> */}
      </div>
    );
  }
}

export default SocialButton;
