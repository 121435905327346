import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import _ from "lodash";

import Footer from "../../components/footer/Footer";
import Breadcrumbs from "../../components/Breadcrumbs";
import DocumentForm from "../../components/document/DocumentForm";
import LoadCurrentDocInfo from "../../components/document/LoadCurrentDocumentInfo";

import * as documentsActions from "../../store/documents/actions";
import * as documentsSelectors from "../../store/documents/selectors";
import * as categoriesActions from "../../store/categories/actions";
import * as categoriesSelectors from "../../store/categories/selectors";
import * as stepsActions from "../../store/steps/actions";
import * as stepsSelectors from "../../store/steps/selectors";
import * as authActions from "../../store/auth/actions";
import * as authSelectors from "../../store/auth/selectors";
import * as glossariesSelectors from "../../store/glossaries/selectors";
import * as glossariesActions from "../../store/glossaries/actions";
import * as draftsActions from "../../store/drafts/actions";
import * as draftsSelectors from "../../store/drafts/selectors";
import * as settingsActions from "../../store/settings/actions";
import * as workflowsActions from "../../store/workflows/actions";
import * as workflowsSelectors from "../../store/workflows/selectors";
import * as exceptionsSelectors from "../../store/exceptions/selectors";
import * as exceptionsActions from "../../store/exceptions/actions";

import strings from "../../services/strings";
import {
  makePATHUrl,
  viewModals,
  getQueryParamStatus,
  getQueryParam,
} from "../../helpers";
import auth from "../../services/auth";
import language from "../../services/language";
import ErrorBoundary from "../../components/errorBoundary/ErrorBoundary";

class DocumentFormPage extends Component {
  componentDidMount() {
    this.checkUserValidation();
    this.props.fetchAllCategories();
    this.props.fetchAllSteps();
    this.props.setCurrentCategoryId(this.props.match.params.catId);
    this.props.setCurrentDocumentId(this.props.match.params.docId);
    this.props.fetchLoggedInUser();
    this.props.getDenyMessage();
    this.props.fetchAllGlossaries();
    this.fetchDocument();
    this.props.validateDoc(this.props.match.params.docId);
    // if (
    //   this.props.location.query.currentWorkflowId &&
    //   this.props.location.query.currentWorkflowId !== ""
    // ) {
    //   this.props.fetchUserWorkflow(
    //     this.props.location.query.currentWorkflowId,
    //     true
    //   );
    // }
    if (getQueryParamStatus(this.props.location, "currentWorkflowId")) {
      this.props.fetchUserWorkflow(
        getQueryParam(this.props.location, "currentWorkflowId"),
        true
      );
    }
  }

  checkUserValidation = () => {
    let draftId = this.props.match.params.id;
    if (draftId) {
      if (!auth.isAuthenticated()) {
        this.props.fetchDraft(draftId);
        _.delay(() => {
          this.props.setCurrentModal(viewModals.SHARED_DOC_MESSAGE);
        }, 1000);
      } else {
        this.fetchDraft();
      }
    }
  };

  fetchDraft = () => {
    this.props.fetchDraft(this.props.match.params.id);
    this.props.setShareDraftId(this.props.match.params.id);
  };

  fetchDocument = () => {
    if (getQueryParamStatus("currentWorkflowId")) {
      this.props.fetchDocument(
        this.props.match.params.docId,
        "docPage",
        getQueryParam("currentWorkflowId")
      );
    } else {
      this.props.fetchDocument(this.props.match.params.docId, "docPage");
    }
    // if (
    //   this.props.location.query.currentWorkflowId &&
    //   this.props.location.query.currentWorkflowId !== ""
    // ) {
    //   this.props.fetchDocument(
    //     this.props.match.params.docId,
    //     "docPage",
    //     this.props.location.query.currentWorkflowId
    //   );
    // }
    // else {
    //   this.props.fetchDocument(this.props.match.params.docId, "docPage");
    // }
  };

  componentWillUnmount() {
    this.props.unsetCurrentCategoryId();
  }

  getCategoryLink = (locale) => {
    let category = this.props.currentCategory;
    if (category) {
      return (
        <Link
          to={`/${locale}/documenten/${makePATHUrl(
            category.name,
            category.id
          )}`}
        >
          {category.name}
        </Link>
      );
    }
  };

  getDocumentLink = (locale) => {
    let doc = this.props.currentDocument;
    let category = this.props.currentCategory;
    if (doc && category) {
      return (
        <Link
          to={`/${locale}/documenten/${makePATHUrl(
            category.name,
            category.id
          )}/${makePATHUrl(doc.name, doc.id)}`}
        >
          {doc.name}
        </Link>
      );
    }
  };

  componentDidUpdate() {
    let draftId = this.props.match.params.id;
    if (draftId) {
    } else {
      this.props.clearDrafts();
    }
  }

  getDocumentForm = () => (
    <DocumentForm
      drafts={this.props.drafts}
      steps={this.props.steps}
      currentCategory={this.props.currentCategory}
      currentItem={this.props.currentDocument}
      attachmentsUrl={this.props.drafts ? this.props.drafts.attachmentsUrl : []}
      downloadItem={(data) =>
        this.props.downloadDocument(this.props.match.params.docId, data)
      }
      profile={this.props.profile}
      denyMessage={this.props.denyMessage}
      glossaries={this.props.glossaries}
      createDraft={(data, documentComments) =>
        this.props.addDraft(data, documentComments)
      }
      createDraftAsRole={(data, documentComments) =>
        this.props.addRoleDraft(data, documentComments)
      }
      updateMyDraft={(data) => this.props.updateMyDraft(data)}
      updateOwnerDraft={(id, data, type, documentComments) =>
        this.props.updateOwnerDraft(id, data, type, documentComments)
      }
      saved={this.props.saved}
      alreadyExist={this.props.alreadyExist}
      disableExist={() => this.props.freshExist()}
      draftShareFields={this.props.draftShareFields}
      fields={this.props.fields}
      fieldsOrder={this.props.fieldsOrder}
      selectors={this.props.selectors}
      clauses={this.props.clauses}
      stepsFromValidation={this.props.stepsFromValidation}
      isRole={false}
      selectorType={
        this.props.match.params.type ? this.props.match.params.type : "default"
      }
      setCurrentDocRulesValueChangeFlag={
        this.props.setCurrentDocRulesValueChangeFlag
      }
      setCurrentModal={this.props.setCurrentModal}
      location={this.props.location}
      userCurrentWorkflow={this.props.userCurrentWorkflow}
      fetchDraft={this.fetchDraft}
    />
  );

  getMetaFields = () => {
    if (_.size(this.props.currentDocument) > 0) {
      return (
        <Helmet>
          <title>{this.props.currentDocument.meta_title}</title>
          <meta
            name="description"
            content={this.props.currentDocument.meta_description}
          />
          <meta
            name="keywords"
            content={this.props.currentDocument.meta_keyword}
          />
        </Helmet>
      );
    }
  };

  getBreadCrumbs = (locale = language.getIso2()) => (
    <Breadcrumbs>
      <Link to={`/${locale}/`}>{strings.get("Client.homePage.home")}</Link>
      <Link to={`/${locale}/documenten`}>
        {strings.get("Client.homePage.title")}
      </Link>
      {_.isEmpty(this.props.subCategoriesList)
        ? null
        : this.props.subCategoriesList.map((subCategory) => (
            <Link
              key={subCategory.id}
              to={`/${locale}/documenten/${makePATHUrl(
                subCategory.name,
                subCategory.id
              )}`}
            >
              {subCategory.name}
            </Link>
          ))}
      {this.getCategoryLink(locale)}
      {this.getDocumentLink(locale)}
    </Breadcrumbs>
  );

  getTopDocumentInfo = () => (
    <LoadCurrentDocInfo
      currentDocument={this.props.currentDocument}
      currentCategory={this.props.currentCategory}
      glossaries={this.props.glossaries}
    />
  );

  render() {
    return (
      <div className="DocumentFormPage">
        <ErrorBoundary>
          {this.getMetaFields()}
          {this.getTopDocumentInfo()}
          {this.getBreadCrumbs()}
          {this.getDocumentForm()}
          <Footer />
        </ErrorBoundary>
      </div>
    );
  }
}

function getSubCategoriesList(state, category) {
  if (_.isObject(category) && category.parent_id) {
    const subCat = categoriesSelectors.getItemById(state, category.parent_id);
    subCategoriesList.push(subCat);
    getSubCategoriesList(state, subCat);
  }
}

let subCategoriesList = [];

function mapStateToProps(state) {
  subCategoriesList = [];
  let currentCategory = categoriesSelectors.getCurrentItem(state);
  getSubCategoriesList(state, currentCategory);
  return {
    drafts: draftsSelectors.getItem(state),
    steps: stepsSelectors.getItems(state),
    currentCategory: currentCategory,
    allCategory: categoriesSelectors.getItems(state),
    currentDocument: documentsSelectors.getCurrentItem(state),
    profile: authSelectors.getProfile(state),
    denyMessage: documentsSelectors.getDenyMessage(state),
    subCategoriesList: subCategoriesList.reverse(),
    glossaries: glossariesSelectors.getItems(state),
    saved: draftsSelectors.isSaved(state),
    alreadyExist: draftsSelectors.alreadyExist(state),
    draftShareFields: draftsSelectors.getShareFields(state),
    fields: draftsSelectors.getFields(state),
    fieldsOrder: draftsSelectors.getFieldsOrder(state),
    selectors: draftsSelectors.getSelectors(state),
    attachmentsUrl: draftsSelectors.getAttachmentsUrl(state),
    clauses: draftsSelectors.getClauses(state),
    stepsFromValidation: draftsSelectors.getSteps(state),
    userCurrentWorkflow: workflowsSelectors.getUserCurrentWorkflow(state),
    exceptions: exceptionsSelectors.getItems(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDocument: (id, type, userWorkflowId) => {
      dispatch(documentsActions.fetchItem(id, type, userWorkflowId));
    },
    fetchAllCategories: () => {
      dispatch(categoriesActions.fetchAllItems());
    },
    fetchUserWorkflow: (id, readOnly) => {
      dispatch(workflowsActions.fetchUserWorkflow(id, readOnly));
    },
    fetchAllSteps: () => {
      dispatch(stepsActions.fetchAllItems());
    },
    downloadDocument: (id, data) => {
      return dispatch(documentsActions.downloadItem(id, data));
    },
    setShareDraftId: (id) => {
      dispatch(documentsActions.setShareDraftId(id));
    },
    setCurrentDocumentId: (id) => {
      dispatch(documentsActions.setCurrentItemId(id));
    },
    setCurrentDocRulesValueChangeFlag: (paths) => {
      dispatch(documentsActions.setCurrentDocRulesValueChangeFlag(paths));
    },
    unsetCurrentDocumentId: () => {
      dispatch(documentsActions.unsetCurrentItemId());
    },
    setCurrentCategoryId: (id) => {
      dispatch(categoriesActions.setCurrentItemId(id));
    },
    unsetCurrentCategoryId: () => {
      dispatch(categoriesActions.unsetCurrentItemId());
    },
    fetchLoggedInUser: () => {
      dispatch(authActions.getUser());
    },
    getDenyMessage: () => {
      dispatch(documentsActions.getDenyMessage());
    },
    fetchAllGlossaries: () => {
      dispatch(glossariesActions.fetchAllItems());
    },
    addDraft: (data, documentComments) => {
      dispatch(draftsActions.createDraft(data, documentComments));
    },
    addRoleDraft: (data, documentComments) => {
      dispatch(draftsActions.createRoleDraft(data, documentComments));
    },
    updateMyDraft: (id, data) => {
      dispatch(draftsActions.updateMyDraft(id, data));
    },
    updateOwnerDraft: (id, data, type, documentComments) => {
      dispatch(draftsActions.updateDraft(id, data, type, documentComments));
    },
    fetchDraft: (id) => {
      dispatch(draftsActions.fetchDraft(id));
    },
    freshExist: () => {
      dispatch(draftsActions.freshExist());
    },
    clearDrafts: () => {
      dispatch(draftsActions.clearDrafts());
    },
    validateDoc: (id) => {
      dispatch(draftsActions.validateItem(id));
    },
    setCurrentModal: (data) => {
      dispatch(settingsActions.setCurrentModal(data));
    },
    clearExceptions: () => {
      dispatch(exceptionsActions.clear());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentFormPage);
