// Here is where you can define configuration overrides based on the execution environment.
// Supply a key to the default export matching the NODE_ENV that you wish to target, and
// the base configuration will apply your overrides before exporting itself.
module.exports = {
  // ======================================================
  // Overrides when NODE_ENV === 'development'
  // ======================================================
  // NOTE: In development, we use an explicit public path when the assets
  // are served webpack by to fix this issue:
  // http://stackoverflow.com/questions/34133808/webpack-ots-parsing-error-loading-fonts/34133809#34133809
  development : (config) => ({
      compiler_public_path : `http://${config.server_host}:${config.server_port}/`
  }),

  // ======================================================
  // Overrides when NODE_ENV === 'production'
  // ======================================================
  production : (config) => ({
    compiler_public_path     : '/',
    compiler_fail_on_warning : false,
    compiler_hash_type       : 'chunkhash',
    compiler_devtool         : 'eval-cheap-module-source-map',
    compiler_stats           : {
      chunks       : true,
      chunkModules : true,
      colors       : true
    }
  }),

  FACEBOOK_APP_ID: '430655140830451', // New Juridocs
  // FACEBOOK_APP_ID: '2288316494566243', // Jai Test App
  // FACEBOOK_APP_ID: '469441754489243', // nadesh Test App
  // FACEBOOK_APP_ID: '868058796714119', // Old Juridocs
  GOOGLE_APP_ID: '818285236910-s22vd454se4kcocgbsgkqt5gi3gj5tul.apps.googleusercontent.com',
  LINKEDIN_APP_ID: '78ihhktzy1mkh7',
  LINKEDIN_APP_SECRET: 'mVfEogdyXoOJ8f1W'
  // LINKEDIN_APP_ID: '86owcmqti36opc' // kathir test app
}
