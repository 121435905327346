import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { CircularProgressbar } from "react-circular-progressbar";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import strings from "../../services/strings";

import "react-circular-progressbar/dist/styles.css";
import "./DocumentFormSteps.scss";

const dummySteps = (
  <SkeletonTheme highlightColor="darkgrey">
    <Skeleton circle={true} width={75} height={75} />
    <Skeleton width={75} height={5} />
    <Skeleton circle={true} width={75} height={75} />
    <Skeleton width={75} height={5} />
    <Skeleton circle={true} width={75} height={75} />
  </SkeletonTheme>
);

class DocumentFormSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: null,
      paths: {},
      isReady: false,
    };
  }

  paths = {};

  componentDidUpdate() {
    if (
      !this.state.steps &&
      _.size(this.props.rules) &&
      _.size(this.props.steps)
    ) {
      let stepIds = this.getStepIds(this.props.rules);
      let steps = _.map(
        _.uniq(stepIds),
        (stepId) => this.props.steps[`_${stepId}`]
      );

      // sort steps by predefined order
      steps = _.sortBy(steps, (step) => {
        if (step) {
          return step.order;
        }
      });
      if (this.props.stepsOrder && this.props.stepsOrder.length) {
        let stepsOrder = JSON.parse(this.props.stepsOrder);
        steps = _.sortBy(steps, function (value, key) {
          return stepsOrder.indexOf(value.name);
        });
      }
      this.props.onLoaded(steps);
      this.setState({ steps });
    }
    if (!this.state.isReady && this.props.rules) {
      this.setState({ isReady: true });
      this.props.rules.forEach((rule) => {
        rule.children &&
          rule.children.forEach((child) => {
            if (
              child.type === "field" &&
              child.properties &&
              !!child.properties.placeholderField
            ) {
              this.paths[child.name] = child.properties.placeholderField;
            }
          });
      });
    }

    if (_.size(this.props.stepFieldsCount) > 0) {
      _.forEach(this.state.steps, (step, key) => {
        if (step.asMutable) step = step.asMutable();
        step["haveFields"] = this.checkStepHaveField(step.id);
        this.state.steps[key] = step;
      });
    }
  }

  getStepIds = (rules, stepIds = []) => {
    _.each(rules, (rule) => {
      if (rule.properties && rule.properties.stepId) {
        stepIds.push(rule.properties.stepId);
      } //else {
      if (rule.children) {
        this.getStepIds(rule.children, stepIds);
      }
      //}
    });

    return stepIds;
  };

  handleStepClick = (step) => {
    if (step.id !== this.props.currentStep.id) {
      this.props.onChange(step);
    }
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  buildLabel = (_label, rules) => {
    let {
      glossariesCodeNameToId,
      glossariesCodeIdToName,
      expandGlossaries,
    } = this.props;
    let result = strings.getFromBetween.get(_label, "[[", "]]");
    result.forEach((e) => {
      const reg = new RegExp(e);

      //if multiple codes are responsible for name of a step
      let stepNameOptions = e.split("||");
      if (stepNameOptions.length > 1) {
        stepNameOptions.forEach((element) => {
          let temp_element = element;
          element = element.trim();
          let optionValue = "";
          if (element.indexOf(".") > -1) {
            let firstCode = element.split(".")[0];
            optionValue =
              sessionStorage[firstCode + "_translation"] ||
              sessionStorage[firstCode] ||
              sessionStorage[
                glossariesCodeNameToId[firstCode] + "_translation"
              ] ||
              sessionStorage[glossariesCodeNameToId[firstCode]];
          } else {
            optionValue =
              sessionStorage[element + "_translation"] ||
              sessionStorage[element] ||
              sessionStorage[
                glossariesCodeNameToId[element] + "_translation"
              ] ||
              sessionStorage[glossariesCodeNameToId[element]];
          }
          if (optionValue !== "" && !!optionValue) {
            _label = _label.replace(e, optionValue).replace(/\[\[|\]\]/g, "");
          } else if (
            stepNameOptions.indexOf(temp_element) + 1 ===
            stepNameOptions.length
          ) {
            // work out pattern [[*.*_*]] for selects
            if (stepNameOptions[0].trim().indexOf(".") > -1) {
              const field = stepNameOptions[0].trim().split(".")[0];
              const values = stepNameOptions[0].trim().split(".")[1].split("_");
              const selected =
                sessionStorage[glossariesCodeNameToId[field]] ||
                sessionStorage[field];
              //if value exist in local storage.
              if (!!selected) {
                const _keys = ["field", "selector", "if"];
                _keys.forEach((type) => {
                  if (
                    this.paths[type] &&
                    (this.paths[type][glossariesCodeNameToId[field]] ||
                      this.paths[type][field])
                  ) {
                    let path = null;
                    if (this.paths[type][field]) {
                      path = this.paths[type][field];
                    } else {
                      path = this.paths[type][glossariesCodeNameToId[field]];
                    }
                    const rule = path[Object.keys(path)[0]].node;

                    if (rule.properties && rule.properties.options) {
                      let valueIndex = rule.properties.options.indexOf(
                        selected
                      );
                      _label = _label
                        .replace(e, values[valueIndex])
                        .replace(/\[\[|\]\]/g, "");
                    }

                    // let i = 0;
                    // for (var item in rule.options) {
                    //   if (item === selected) break;
                    //   else i++;
                    // }
                    // _label = _label.replace(e, values[i]).replace(/\[\[|\]\]/g, "");
                  }
                });
              }
              //if not exist in local storage, then use placeholder.
              else {
                const _keys = ["field", "selector", "if"];
                _keys.forEach((type) => {
                  if (
                    this.paths[type] &&
                    (this.paths[type][glossariesCodeNameToId[field]] ||
                      this.paths[type][field])
                  ) {
                    let path = null;
                    if (this.paths[type][field]) {
                      path = this.paths[type][field];
                    } else {
                      path = this.paths[type][glossariesCodeNameToId[field]];
                    }
                    const rule = path[Object.keys(path)[0]].node;
                    let _placeholder = null;
                    if (rule.properties) {
                      _placeholder = rule.properties.placeholderField;
                    }

                    if (_placeholder) {
                      _label = _label
                        .replace(e, _placeholder)
                        .replace(/\[\[|\]\]/g, "");
                    }
                  } else {
                    _label = _label
                      .replace(e, "< ## >")
                      .replace(/\[\[|\]\]/g, "");
                  }
                });
              }
            } else if (expandGlossaries && _.size(expandGlossaries)) {
              if (
                expandGlossaries[stepNameOptions[0].trim()] &&
                expandGlossaries[stepNameOptions[0].trim()].placeholderField
              ) {
                _label = _label
                  .replace(
                    e,
                    expandGlossaries[stepNameOptions[0].trim()].placeholderField
                  )
                  .replace(/\[\[|\]\]/g, "");
              } else if (
                expandGlossaries[
                  glossariesCodeIdToName[stepNameOptions[0].trim()]
                ] &&
                expandGlossaries[
                  glossariesCodeIdToName[stepNameOptions[0].trim()]
                ].placeholderField
              ) {
                _label = _label
                  .replace(
                    e,
                    expandGlossaries[
                      glossariesCodeIdToName[stepNameOptions[0].trim()]
                    ].placeholderField
                  )
                  .replace(/\[\[|\]\]/g, "");
              } else {
                _label = _label.replace(e, "< ## >").replace(/\[\[|\]\]/g, "");
              }
            }
          }
        });
      } else {
        //if single code is responsible for name of a step
        const _value =
          sessionStorage[e + "_translation"] ||
          sessionStorage[e] ||
          sessionStorage[glossariesCodeNameToId[e] + "_translation"] ||
          sessionStorage[glossariesCodeNameToId[e]];
        if (_value !== "" && !!_value) {
          _label = _label.replace(reg, _value).replace(/\[\[|\]\]/g, "");
        } else {
          if (e.indexOf(".") > -1) {
            const field = e.split(".")[0];
            const values = e.split(".")[1].split("_");
            const selected =
              sessionStorage[glossariesCodeNameToId[field]] ||
              sessionStorage[field];
            if (!!selected) {
              const _keys = ["field", "selector", "if"];
              _keys.forEach((type) => {
                if (
                  this.props.paths &&
                  this.props.paths[type] &&
                  (this.props.paths[type][glossariesCodeNameToId[field]] ||
                    this.props.paths[type][field])
                ) {
                  let path = null;
                  if (this.props.paths[type][glossariesCodeNameToId[field]]) {
                    path = this.props.paths[type][
                      glossariesCodeNameToId[field]
                    ];
                  } else {
                    path = this.props.paths[type][field];
                  }
                  // const path = this.paths[e][glossariesCodeNameToId[field] || field];
                  const rule = path[Object.keys(path)[0]].node;

                  if (rule.properties && rule.properties.options) {
                    let valueIndex = rule.properties.options.indexOf(selected);
                    _label = _label
                      .replace(reg, values[valueIndex])
                      .replace(/\[\[|\]\]/g, "");
                  }
                  // let i = 0;
                  // for (var item in rule.options) {
                  //   if (item === selected) break;
                  //   else i++;
                  // }
                  // _label = _label
                  //   .replace(reg, values[i])
                  //   .replace(/\[\[|\]\]/g, "");
                }
              });
            } else {
              const _keys = ["field", "selector", "if"];
              _keys.forEach((type) => {
                if (
                  this.props.paths &&
                  this.props.paths[type] &&
                  this.props.paths[type][glossariesCodeNameToId[field] || field]
                ) {
                  const path = this.props.paths[type][
                    glossariesCodeNameToId[field] || field
                  ];
                  const rule = path[Object.keys(path)[0]].node;

                  let _placeholder = null;
                  if (rule.properties) {
                    _placeholder = rule.properties.placeholderField;
                  }
                  if (_placeholder) {
                    _label = _label
                      .replace(e, _placeholder)
                      .replace(/\[\[|\]\]/g, "");
                  }
                } else {
                  _label = _label
                    .replace(e, "< ## >")
                    .replace(/\[\[|\]\]/g, "");
                }
              });
            }
          } else {
            if (
              this.props.paths &&
              this.props.paths["field"] &&
              (this.props.paths["field"][e] ||
                this.props.paths["field"][glossariesCodeNameToId[e]])
            ) {
              let _path = null;
              if (this.props.paths["field"][e]) {
                _path = this.props.paths["field"][e];
              } else {
                _path = this.props.paths["field"][glossariesCodeNameToId[e]];
              }
              if (_path) {
                const _placeholder =
                  _path[Object.keys(_path)[0]].node.properties.placeholderField;
                if (_placeholder) {
                  _label = _label
                    .replace(reg, _placeholder)
                    .replace(/\[\[|\]\]/g, "");
                }
              }
            } else {
              if (this.paths[e]) {
                _label = _label
                  .replace(reg, this.paths[e])
                  .replace(/\[\[|\]\]/g, "");
              } else {
                if (expandGlossaries && _.size(expandGlossaries)) {
                  if (
                    expandGlossaries[e] &&
                    expandGlossaries[e].placeholderField
                  ) {
                    _label = _label
                      .replace(reg, expandGlossaries[e].placeholderField)
                      .replace(/\[\[|\]\]/g, "");
                  } else if (
                    expandGlossaries[glossariesCodeIdToName[e]] &&
                    expandGlossaries[glossariesCodeIdToName[e]].placeholderField
                  ) {
                    _label = _label
                      .replace(
                        reg,
                        expandGlossaries[glossariesCodeIdToName[e]]
                          .placeholderField
                      )
                      .replace(/\[\[|\]\]/g, "");
                  } else {
                    _label = _label
                      .replace(reg, "< ## >")
                      .replace(/\[\[|\]\]/g, "");
                  }
                }
              }
            }
          }
        }
      }
    });
    return this.capitalizeFirstLetter(_label);
  };

  getCompletedFieldsCount = (id) => {
    if (this.props.stepFieldsCount) {
      let fieldCount = this.props.stepFieldsCount;
      let step = _.find(fieldCount, function (o) {
        return o.id === id;
      });
      if (step) {
        // if(step.filled === step.total){
        //     return ( <span className="step-completed ion-ios-checkmark text-success"></span> );
        // }
        return (
          <span>
            {step.filled} {strings.get("Client.documentContent.of")}{" "}
            {step.total}
          </span>
        );
      }
    }
  };

  getCompletedFieldsPercentage = (id) => {
    if (this.props.stepFieldsCount) {
      let fieldCount = this.props.stepFieldsCount;
      let step = _.find(fieldCount, function (o) {
        return o.id === id;
      });
      if (step) {
        return (step.filled / step.total) * 100;
      }
    }
  };

  getSteps = () => {
    if (this.state.steps && this.props.currentStep) {
      return _.map(this.state.steps, (step, key) => {
        let className =
          step.id === this.props.currentStep.id ? "activeName" : "";
        return (
          <li
            key={step.id}
            className={"active " + className}
            onClick={() => this.handleStepClick(step)}
          >
            <div className="number-div">
              <span className="number">{key + 1}</span>
            </div>
            <div className="name-div">
              <span className="name">
                {this.buildLabel(step.name, this.props.rules)}
              </span>
            </div>
            <div className="fieldsCount-div">
              {this.getCompletedFieldsCount(step.id)}
            </div>
          </li>
        );
      });
    }
  };

  checkStepCompleted = (id) => {
    if (this.props.stepFieldsCount) {
      let fieldCount = this.props.stepFieldsCount;
      let step = _.find(fieldCount, function (o) {
        return o.id === id;
      });
      if (step) {
        if (step.filled === step.total) {
          return true;
        }
        return false;
      }
    }
  };

  checkStepHaveField = (id) => {
    if (this.props.stepFieldsCount) {
      let fieldCount = this.props.stepFieldsCount;
      // console.log(fieldCount);
      let step = _.find(fieldCount, function (o) {
        return o.id === id;
      });

      if (step) {
        if (step.total) {
          return true;
        }
        return false;
      }
    }
  };

  getHorizontalSteps = () => {
    if (this.state.steps && this.props.currentStep) {
      let steps = this.state.steps;
      let stepNo = 0;
      //console.log('*****************',this.state.steps);
      if (this.props.stepsOrder && this.props.stepsOrder.length) {
        let stepsOrder = JSON.parse(this.props.stepsOrder);
        steps = _.sortBy(steps, function (value, key) {
          return stepsOrder.indexOf(value.name);
        });
      }
      const availableSteps = 
        _.filter(steps, (step, key) => this.checkStepHaveField(step.id));

      if(availableSteps.length===1) {
        this.handleStepClick(availableSteps[0]);
      }
       
      return _.map(availableSteps, (step, key) => {
          let stepFinished = this.checkStepCompleted(step.id);
          let className = "unfinished";
          if (step.id === this.props.currentStep.id) {
            className = "current";
          } else if (stepFinished) {
            className = "finished";
          }

          let percentage = this.getCompletedFieldsPercentage(step.id);
          stepNo += 1;
          return (
            <div
              key={step.id}
              onClick={() => this.handleStepClick(step)}
              className={"wizard-step " + className}
            >
              <div className="wizard-icon">
                <CircularProgressbar
                  percentage={percentage}
                  text={`${stepNo}`}
                  strokeWidth={3}
                  background
                  backgroundPadding={6}
                  styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the circle behind the path
                    trail: {
                      // Tweak the trail color:
                      // stroke: '#C9C9C9',
                    },
                    // Customize the path, i.e. the part that's "complete"
                    path: {
                      // Tweak path color:
                      // stroke: '#3399FF',
                      // Tweak path to use flat or rounded ends:
                      strokeLinecap: "butt",
                      // Tweak transition animation:
                      transition: "stroke-dashoffset 0.5s ease 0s",
                    },
                    // Customize the text
                    text: {
                      // Tweak text color:
                      fill: "#6e6e6e",
                      // Tweak text size:
                      fontSize: "30px",
                    },
                  }}
                />
              </div>
              <div className="wizard-content hidden-xs">
                <div className="title">
                  {this.buildLabel(step.name, this.props.rules)}
                </div>
                <div className="description">
                  {this.getCompletedFieldsCount(step.id)}
                </div>
              </div>
            </div>
          );
      });
    }
  };

  render() {
    return (
      <div className="DocumentFormSteps" ref="wrapper">
        <div>
          {this.getHorizontalSteps() === undefined ? (
            <div className="dummySteps">{dummySteps}</div>
          ) : (
            <div className="flexbox-wizard">{this.getHorizontalSteps()}</div>
          )}
        </div>
      </div>
    );
  }
}

DocumentFormSteps.propTypes = {
  steps: PropTypes.object,
  rules: PropTypes.array,
  currentStep: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onLoaded: PropTypes.func.isRequired,
};

export default DocumentFormSteps;
